import {makeStyles} from '@mui/styles'
import {Theme} from '@mui/material'
import { blue } from '@mui/material/colors'

export default makeStyles((theme:Theme) => ({
    clusterText: {
        fontFamily: 'Roboto',
        fontSize: '1.5rem',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '50px',
        width: '50px',
        fontWeight: 'bold',
        background: theme.palette.common.white,
        '& .MuiCircularProgress-root': {
            width: '50px !important',
            height: '50px !important'
        },
    },
    top: {
        width: '50px',
        height: '50px',
        animationDuration: '550ms',
        position: 'absolute',
        '&.MuiCircularProgress-root': {
            color: theme.palette.secondary.main
        }
    },
    bottom: {
        color: theme.palette.grey[700],
        '&.MuiCircularProgress-root': {
            color: blue[100]
        }
    },
    countNumber: {
        color: theme.palette.grey[700]
    },
    blueColor: {
        color: theme.palette.secondary.main
    },
    fab: {
        '&.MuiFab-root': {
            transform: 'scale(1)',
            color: theme.palette.common.white,
            background: theme.palette.grey[700],
            border: `3px solid ${theme.palette.common.white}`,
            height: '50px',
            '&:hover': {
                background: theme.palette.grey[500],
            }
        }
    },
    initializingIcon: {
        marginRight: '5px !important'
    }
}))