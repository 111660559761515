import React, {useEffect, useState} from 'react'
import useStyles from './styles'
import {Box, Fab} from "@mui/material"
import {GpsFixed} from "@mui/icons-material"
import useMediaQuery from "@mui/material/useMediaQuery"
import useCoordinates from "../../../../hooks/useCoordinates"
import useCurrentLocation from "../../../../hooks/useCurrentLocation"
import {getParamValue} from "../../../../utils"
import useDefaultCenter from "../../../../hooks/useDefaultCenter"
import useConfirmDialogPlaces from "../../../../hooks/useConfirmDialogPlaces"
import useSelectedPlace from "../../../../hooks/useSelectedPlace"
import useZoomLevel from "../../../../hooks/useZoomLevel"
import {getZoomLevel} from '../../../../utils'

import Cookies from 'universal-cookie'

const Recenter = () => {
    const classes = useStyles()
    const isDesktop = useMediaQuery('(min-width: 900px)')
    const {setCoordinates} = useCoordinates()
    const {currentLocation} = useCurrentLocation()
    const {defaultCenter} = useDefaultCenter()
    const {zoomLevel, setZoomLevel} = useZoomLevel()

    const isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1
    const isIFrame = Boolean(getParamValue('isIframe')) || false

    const {setIsPlaceConfirmDialogOpen} = useConfirmDialogPlaces()
    const {selectedPlace} = useSelectedPlace()

    const cookies = new Cookies()

    const recenterPosition = () => {
        setZoomLevel(getZoomLevel(zoomLevel))
        setTimeout(() => {
            if(selectedPlace) {
                setIsPlaceConfirmDialogOpen(true)
                return
            }
            if(isFirefox && isIFrame) {
                const latitude = Number(getParamValue('lat')) || null
                const longitude = Number(getParamValue('lng')) || null
                if (latitude && longitude) {
                    currentLocation ? setCoordinates(currentLocation) : setCoordinates(defaultCenter)
                }
            } else {
                currentLocation ? setCoordinates(currentLocation) : setCoordinates(defaultCenter)
            }
        }, 1000)
    }

    // TODO - Andrej - FIX!
    const [recenter, setRecenter] = useState(false)
    useEffect(() => {
        // const location_id = getParamValue('locationId')
        const location_id = cookies.get('gw_location_id') || null
        if(location_id === null && recenter === false) {
            recenterPosition()
            setRecenter(true)
        }
    }, [currentLocation])

    return (
        <>
            <Box className={isDesktop ? classes.recenterWrapperDesktop : classes.recenterWrapper}
                 position="relative"
                 display="flex"
                 boxShadow={2}
                 borderRadius="50%"
            >
                <Fab aria-label="recenter"
                     className={classes.fab}
                     onClick={()=>{recenterPosition()}}
                >
                    <GpsFixed/>
                </Fab>
            </Box>
        </>
    )
}

export default Recenter
