import {makeStyles} from '@mui/styles';
import {Theme} from '@mui/material'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// @ts-ignore
export default makeStyles((theme: Theme) => ({
    avatar: {
        border: `1px solid ${theme.palette.grey[300]}`,
        '&.MuiAvatar-root': {
            borderRadius: '15px',
        }
    },
    actionButtonsBar: {
        justifyContent: 'center',
        alignItems: 'center',
        paddingTop: '1rem'
    },
    accessInstructions: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        WebkitLineClamp: 3,
        WebkitBoxOrient: 'vertical',
        display: '-webkit-box',
    },

    container: {
        [theme.breakpoints.down("md")]: {
            padding: '25px',
            '& .MuiDrawer-root > .MuiPaper-root': {
                height: `calc(50% - 56px)`,
                overflow: 'visible',
            }
        }
    },
    drawerContainer: {
        overflow: 'auto',
        padding: '0 0.5rem',
        '& .MuiGrid-root': {
            alignItems: 'center'
        },
        [theme.breakpoints.up("md")]: {
            marginTop: '60px'
        }
    },
    chip: {
        margin: '5px 5px 5px 0'
    },
    loading: {
        height: '600px', display: 'flex', justifyContent: 'center', alignItems: 'center'
    },
    subtitle: {
        display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: '10px'
    },
    spacing: {
        display: 'flex', alignItems: 'center', justifyContent: 'space-between'
    },
}));