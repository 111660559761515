import React, {useState} from "react"
import Slider from "react-slick"
import useStyles from "./styles"
import {Avatar, Dialog, IconButton, ImageListItem, ImageListItemBar} from "@mui/material"
import CloseIcon from '@mui/icons-material/Close'
import useToggleGallery from "../../../../hooks/useToggleGallery"
import {useTranslation} from "react-i18next"
import useMediaQuery from "@mui/material/useMediaQuery";

const Gallery = ({photos}) => {
    const isDesktop = useMediaQuery('(min-width: 900px)')
    const {t} = useTranslation()
    const classes = useStyles()
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        appendDots: dots => (
            <div className={classes.dotStyleWrapper}>
                <ul>{dots}</ul>
            </div>
        ),
        customPaging: index => (
            <Avatar src={`${photos[index].path}?w=200`}
                    alt={photos[index]['short_description']}
                    variant="rounded"
                    sx={{
                        width: 64,
                        height: 64
                    }}
                    className={classes.dotStyle}
            />
        )
    }

    const {toggleGallery, setToggleGallery} = useToggleGallery()

    const [nav1, setNav1] = useState(null)
    const [nav2, setNav2] = useState(null)

    return (
        <div>
            {
                photos && (
                    <Dialog open={toggleGallery}
                            fullScreen={true}
                            className={classes.galleryWrapper}
                    >
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={() => {setToggleGallery(false)}}
                            aria-label={t('locationMap.close')}
                            className={classes.closeButton}
                        >
                            <CloseIcon />
                        </IconButton>


                        <div className={isDesktop ? classes.sliderWrapperDesktop : classes.sliderWrapper}>
                            <div>
                                <Slider asNavFor={nav2}
                                        ref={slider => (setNav1(slider))}
                                        {...settings}
                                        className={isDesktop ? classes.sliderTopDesktop : classes.sliderTopMobile}
                                >
                                    {
                                        photos.map((photo, index) => (
                                            <ImageListItem key={index}
                                            >
                                                <img src={photo.path} alt={photo['short_description']}/>
                                                {
                                                    photo.description && <ImageListItemBar title={photo.description}/>
                                                }
                                            </ImageListItem>
                                        ))
                                    }
                                </Slider>

                            </div>
                        </div>
                    </Dialog>
                )
            }
        </div>
    )
}

export default Gallery