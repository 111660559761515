import {makeStyles} from '@mui/styles'
import {Theme} from '@mui/material'

export default makeStyles((theme:Theme) => ({
    logo: {
        display: 'flex',
        justifyContent: 'space-around',
        position: 'absolute',
        width: 'calc(100% - 120px)',
        marginLeft: '60px',
        top: '15px'
    }
}))