import {RootState} from './../store'
import {useDispatch, useSelector} from 'react-redux'
import {setFieldValueOfMap} from "../store/features/Map";

const useConfirmDialogPlaces = () => {
    const dispatch = useDispatch()
    const isPlaceConfirmDialogOpen = useSelector((state: RootState) => state.map.isPlaceConfirmDialogOpen)
    const setIsPlaceConfirmDialogOpen = (value: boolean | null) => {
        dispatch(setFieldValueOfMap({
            field: 'isPlaceConfirmDialogOpen',
            value
        }))
    }

    return {
        isPlaceConfirmDialogOpen,
        setIsPlaceConfirmDialogOpen
    }
}

export default useConfirmDialogPlaces