import {makeStyles} from '@mui/styles';
import {Theme} from '@mui/material'
import {grey} from "@mui/material/colors";

export default makeStyles((theme: Theme) => ({
    currentStateWrapperDesktop: {
        width: '40%',
        margin: 'left',
        '& .MuiPaper-root': {
            background: 'none'
        }
    },
    confirmDialog: {
        '&.MuiDialogTitle-root': {
            textAlign: 'center'
        },
        '& .MuiDialogContentText-root': {
            textAlign: 'center',
            color: theme.palette.common.black
        },
        '& .MuiDialogActions-root': {
            display: 'flex',
            justifyContent: 'space-evenly',
            paddingBottom: '20px'
        }
    },
    cancelButton: {
        '&.MuiButton-root': {
            height: '2.5rem',
            borderRadius: 25,
            padding: '0 20px',
            borderColor: theme.palette.grey["800"],
            color: theme.palette.grey["800"]
        }
    },
    terminateButton: {
        '&.MuiButton-root': {
            height: '2.5rem',
            borderRadius: 25,
            padding: '0 20px'
        }
    },
    dialogContent: {
        background: theme.palette.common.white,
        '&.MuiDialogContent-root': {
            padding: 0,
            display: 'flex',
            flexDirection: 'column',
            overflowY: 'auto'
        }
    },
    avatar: {
        '&.MuiAvatar-root': {
            background: theme.palette.secondary.main,
            width: '175px',
            height: '175px'
        }
    },
    avatarButton: {
        '&.MuiAvatar-root': {
            '&:hover': {
                background: theme.palette.secondary.dark,
                cursor: 'pointer'
            }
        }
    },
    avatarContent: {
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center'
    },
    dialogTitleDesktop: {
        '&.MuiDialogTitle-root': {
            borderRadius: 0
        }
    },
    dialogTitle: {
        borderTopLeftRadius: '15px',
        borderTopRightRadius: '15px',
        background: theme.palette.secondary.main,
        textAlign: 'center'
    },
    title: {
        color: theme.palette.common.white,
        '&.MuiTypography-root': {
            fontSize: '1rem',
            fontWeight: 700
        }
    },
    slider: {
        background: theme.palette.secondary.light,
        height: '265px',
        '& .slick-dots': {
            background: theme.palette.secondary.light,
            paddingBottom: '15px',
            '& li button::before': {
                color: theme.palette.common.white
            }
        }
    },
    offlineBackground: {
        background: theme.palette.grey["700"]
    },
    noCommunication: {
        background: theme.palette.grey["600"],
        '& .MuiAvatar-root': {
            background: theme.palette.grey["500"],
        },
        '& .slick-dots': {
            background: theme.palette.grey["600"],
            paddingBottom: '15px',
            '& li button::before': {
                color: theme.palette.common.white
            }
        }
    },
    listWrapper: {
        '&.MuiList-root': {
            marginTop: '15px',
            maxHeight: '250px',
            overflowY: 'auto'
        }
    },
    listItem: {
        '&.MuiListItem-root': {
            justifyContent: 'center',
            height: '265px'
        }
    },
    closeButton: {
        '&.MuiButtonBase-root': {
            opacity: '0.75',
            color: theme.palette.common.white
        }
    },
    stateOfChargeTitle: {
        paddingBottom: '10px',
        '&.MuiTypography-root': {
            fontWeight: '600'
        }
    },
    stateOfChargePercentageValue: {
        fontSize: '3rem'
    },
    stateOfChargePercentageSign: {
        fontSize: '1rem',
        paddingTop: '10px'
    },
    connectorsWrapper: {
        '& .MuiListItem-root': {
            padding: '0 10px 0 0'
        }
    },
    connectorListItem: {
        paddingLeft: 0
    },
    connectorLeftSideWrapper: {
        '&.MuiListItem-root': {
            paddingLeft: 0,
            paddingRight: 0,
        },
        '& .MuiListItemText-secondary': {
            color: grey[700]
        }
    },
    connectorPrice: {
        '& .MuiListItemText-primary': {
            fontWeight: theme.typography.fontWeightBold,
            marginBottom: '5px'
        }
    },
    connectorIcon: {
        '&.MuiListItemText-root': {
            textAlign: 'center',
            paddingRight: '25px'
        },
        '& img': {
            width: '40px'
        }
    },
    list: {
        height: '75vh',
        overflow: 'auto',
    },
    listItemConnector: {
        minHeight: '75px',
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        padding: '0.5rem 0'
    },
    locationInfoWrapper: {
        lineHeight: 1
    },
    locationAdditionalInfoWrapper: {
        lineHeight: 1,
        maxWidth: '95px',
        float: 'right'
    },
    avatarConnector: {
        border: `1px solid ${theme.palette.grey[300]}`,
        '&.MuiAvatar-root': {
            borderRadius: '15px',
        }
    },
    ownerType: {
        color: theme.palette.common.black
    },
    ListItemSecondary: {
        right: '5px !important',
        textAlign: 'right'
    },
    maxPower: {
        color: theme.palette.common.black,
        '&.MuiTypography-root': {
            lineHeight: 1.5,
            fontSize: '0.875rem'
        }
    },
    titleWrapper: {
        '&.MuiTypography-root': {
            color: theme.palette.common.black,
            fontSize: '0.875rem',
            fontWeight: '600'
        }
    },
    endChargingSession: {
        width: '100%',
        '&.MuiButton-root': {
            height: '3rem',
            borderRadius: 25
        }
    },
    bigCloseButton: {
        width: '100%',
        '&.MuiButton-root': {
            height: '3rem',
            marginTop: '1.5rem',
            borderRadius: 25,
            borderColor: `${theme.palette.grey["600"]} !important`,
            color: `${theme.palette.grey["600"]} !important`,
            '&:hover': {
                cursor: 'pointer',
                borderColor: `${theme.palette.grey["900"]} !important`,
                color: `${theme.palette.grey["900"]} !important`
            }
        }
    },
    grid: {
        height: '100%',
        '& .MuiGrid-grid-xs-12': {
            padding: '0 10px',
            paddingBottom: '20px'
        }
    },
    bottomPart: {
        display: 'flex',
        justifyContent: 'flex-end',
        '&.MuiGrid-root': {
            flexDirection: 'column'
        }
    }
}))