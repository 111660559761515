import {useDispatch, useSelector} from 'react-redux'
import {setFieldValueOfLocation} from '../store/features/Location'
import {RootState} from './../store'

const useToggleOpeningHours = () => {
    const dispatch = useDispatch()
    const toggleOpeningHours = useSelector((state: RootState) => state.location.toggleOpeningHours)
    const setToggleOpeningHours = (value) => {
        dispatch(setFieldValueOfLocation({
            field: 'toggleOpeningHours',
            value
        }))
    }

    return {
        toggleOpeningHours,
        setToggleOpeningHours
    }
}

export default useToggleOpeningHours