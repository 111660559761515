import {Avatar, Badge, Card, Chip, Grid, IconButton, Stack, Typography} from "@mui/material"
import {InfoOutlined, Navigation, QrCode2Outlined} from "@mui/icons-material"
import useStyles from './styles'
import IconQr from '../../../../assets/icons/scanQr.svg'

import useToggleAccessInstructions from "../../../../hooks/useToggleAccessInstructions"
import useCurrentLocation from "../../../../hooks/useCurrentLocation"
import useToggleGallery from "../../../../hooks/useToggleGallery"
import useToggleScanQR from "../../../../hooks/useToggleScanQR"
import {useTranslation} from "react-i18next"
import useCurrentStates from "../../../../hooks/useCurrentStates"
import useMediaQuery from "@mui/material/useMediaQuery"
import moment from "moment/moment"
import PSilver from "../../../../assets/icons/icon-p-silver.svg"
import RwSilver from "../../../../assets/icons/icon-r-silver.svg"
import GwSilver from "../../../../assets/icons/icon-gw-silver.svg"
import ReactMarkdown from "react-markdown";
import React from "react";

const TopSection = ({place, openingHoursInfo}) => {
    const classes = useStyles()
    const { t } = useTranslation()
    const isDesktop = useMediaQuery('(min-width: 900px)')
    const {setToggleAccessInstructions} = useToggleAccessInstructions()
    const {currentLocation} = useCurrentLocation()
    const {setToggleGallery} = useToggleGallery()
    const {currentStates} = useCurrentStates()

    // const test_access_instructions = `**Remember: a Fair Use Policy applies at roaming stations. You can find more information** [here](https://data.greenway.sk/clientzone/pricelist_SK_20230701.pdf).`

    //iOs Detection
    const userAgent = navigator.userAgent
    let isIOS = false

    // FF
    const isFupEnabled = false

    if (userAgent.includes('iOS') ||
        userAgent.includes('iPhone') ||
        userAgent.includes('iPad') ||
        userAgent.includes('iPod') ||
        userAgent.includes('Safari')
    ) {
        isIOS = true
    }

    const {setToggleScanQR} = useToggleScanQR()

    const getGalleryFallbackIcon = (defaultPhoto: string, ownerType: string) => {
        if(defaultPhoto) {
            return `${defaultPhoto}?w=200`
        }
        switch (ownerType?.toLowerCase()) {
            case 'partner':
                return PSilver
            case 'roaming':
                return RwSilver
            case 'greenway':
            default:
                return GwSilver
        }
    }

    const getStatusColor = (place) => {
        if(place && place?.changes_at?.length) {
            const changesAt = moment(place['changes_at'][0])
            const now = moment.utc()
            const diff = changesAt.diff(now, "minutes")

            // if it's less than an hour
            if(Math.abs(diff) <= 60) {
                return classes['warning']
            }
        }

        switch (place.availability?.toLowerCase()) {
            case 'available':
            case 'preparing':
            case 'finishing':
                return classes['available']
            case 'maintenance':
                return classes['error']
            case 'closed':
            case 'offline':
            case 'reserved':
            case 'newly_inserted':
            case 'occupied':
            case 'out_of_communication':
            case 'out_of_working_time':
            case 'suspended_evse':
            case 'suspended_ev':
                return classes['offline']
            default:
                return classes['offline']
        }
    }

    const triggerNavigation = (place) => {
        let url = `http://maps.google.com/maps?daddr=${place.latitude},${place.longitude}`
        if (currentLocation) {
            url = `${url}&saddr=${currentLocation.lat},${currentLocation.lng}`
        }
        window.open(url, '_blank')
    }

    return (
        <div className={classes.container}>
            <Card elevation={0}>
                <Grid container className="title-qr-wrapper">
                    <Grid item xs={10}>
                        <Typography gutterBottom paddingTop={2}
                                    className={`${classes.openingHours} ${getStatusColor(place)}`}>
                            {openingHoursInfo}
                        </Typography>
                    </Grid>
                    {
                        !currentStates?.length && (
                            <Grid item xs={2}>
                                <IconButton
                                    edge="start"
                                    color="inherit"
                                    onClick={() => {
                                        setToggleScanQR(true)
                                    }}
                                    aria-label={t('locationMap.close')}
                                    className={classes.button}
                                >
                                    <Avatar sx={{bgcolor: 'rgba(0, 153, 255, 0.1)'}}>
                                        <img src={IconQr} className={classes['icon']} alt={t('locationMap.scanQR')}/>
                                    </Avatar>
                                </IconButton>
                            </Grid>
                        )
                    }
                </Grid>

                <Typography gutterBottom variant="h5" className={classes.title}>
                    {place.name}
                </Typography>

                <Typography gutterBottom variant={"subtitle1"} className={classes.address}>
                    {place.street} {place['house_number']}, {place.city}
                </Typography>
                <Grid container
                      spacing={0}
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                >
                    <Grid item xs={9}>
                        {
                            place.access_instructions && (
                                <Typography variant={"subtitle1"}
                                            className={classes.accessInstructions}
                                            onClick={() => {
                                                setToggleAccessInstructions(true)
                                            }}
                                >
                                    <ReactMarkdown components={{
                                        a({ node, children, ...props }) {
                                            try {
                                                new URL(props.href ?? "");
                                                // If we don't get an error, then it's an absolute URL.

                                                props.target = "_blank";
                                                props.rel = "noopener noreferrer";
                                            } catch (e) {}

                                            return <a {...props}>{children}</a>;
                                        },
                                    }}>
                                        {place.access_instructions}
                                    </ReactMarkdown>
                                </Typography>
                            )
                        }
                        {
                            place.owner_type === "Roaming" && isFupEnabled  && (
                                <Typography variant={"subtitle1"}
                                            className={classes.accessInstructions}
                                >
                                    <ReactMarkdown components={{
                                        a({ node, children, ...props }) {
                                            try {
                                                new URL(props.href ?? "");
                                                // If we don't get an error, then it's an absolute URL.

                                                props.target = "_blank";
                                                props.rel = "noopener noreferrer";
                                            } catch (e) {}

                                            return <a {...props}>{children}</a>;
                                        },
                                    }}>
                                        { t('locations.FairUsePolicy') }
                                    </ReactMarkdown>
                                </Typography>
                            )
                        }
                    </Grid>
                    <Grid item xs={2}
                          className={classes.badgeWrapper}
                    >
                        {
                            place && (
                                <Badge color="info"
                                       badgeContent={place?.photos?.length}
                                >
                                    <Avatar alt={place.name}
                                            src={place['default_photo'] ? `${place['default_photo']}?w=200` : getGalleryFallbackIcon(place['default_photo'], place['owner_type'])}
                                            variant="rounded"
                                            sx={{
                                                width: 64,
                                                height: 64,
                                                marginRight: '-7px'
                                            }}
                                            className={`${classes.avatar} ${place['default_photo'] === null ? classes.avatarFallbackImage : ''}` }
                                            onClick={() => {
                                                if(place['photos'].length) {
                                                    setToggleGallery(true)
                                                }
                                            }}
                                    />
                                </Badge>
                            )
                        }

                    </Grid>
                    <Grid item xs={12}
                    >
                        <Stack direction="row"
                               spacing={1}
                               className={classes.actionButtonsBar}
                        >
                            {
                                isDesktop || isIOS ? (
                                    <Chip
                                        icon={<Navigation/>}
                                        label={t('locationMap.navigate')}
                                        component="a"
                                        variant="outlined"
                                        onClick={() => {
                                            triggerNavigation(place)
                                        }}
                                    />
                                ) : (
                                    <a href={`geo:${currentLocation.lat},${currentLocation.lng}?q=${place.latitude},${place.longitude}`}
                                       target='_blank'
                                       rel="noreferrer"
                                    >
                                        <Chip
                                            icon={<Navigation/>}
                                            label={t('locationMap.navigate')}
                                            component="a"
                                            variant="outlined"
                                        />
                                    </a>
                                )
                            }
                            {
                                place['access_instructions'] && place['access_instructions'].length && (
                                    <Chip
                                        icon={<InfoOutlined/>}
                                        label={t('locationMap.accessInstructions')}
                                        component="a"
                                        variant="outlined"
                                        clickable
                                        onClick={() => {
                                            setToggleAccessInstructions(true)
                                        }}
                                    />
                                )
                            }
                            {
                                !currentStates?.length && (
                                    <Chip
                                        icon={<QrCode2Outlined/>}
                                        label={t('locationMap.scanQR')}
                                        component="a"
                                        variant="outlined"
                                        onClick={() => {
                                            setToggleScanQR(true)
                                        }}
                                    />
                                )
                            }
                        </Stack>
                    </Grid>
                </Grid>
            </Card>
        </div>
    )
}

export default TopSection
