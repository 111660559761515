import {useDispatch, useSelector} from 'react-redux'
import {setFieldValueOfLocation} from '../store/features/Location'
import {RootState} from './../store'

const useToggleScanQR = () => {
    const dispatch = useDispatch()
    const toggleScanQR = useSelector((state: RootState) => state.location.toggleScanQR)
    const setToggleScanQR = (value) => {
        dispatch(setFieldValueOfLocation({
            field: 'toggleScanQR',
            value
        }))
    }

    return {
        toggleScanQR,
        setToggleScanQR
    }
}

export default useToggleScanQR