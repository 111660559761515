import {useDispatch, useSelector} from 'react-redux'
import {setFieldValueOfLocation} from '../store/features/Location'
import {RootState} from './../store'
import {ILocationsList} from './../interface/locationsList'

const useCommandState = () => {
    const dispatch = useDispatch()

    const commandState = useSelector((state: RootState) => state.location.commandState)
    const setCommandState = (value: ILocationsList | {}) => {
        dispatch(setFieldValueOfLocation({
            field: 'commandState',
            value
        }))
    }

    return {
        commandState,
        setCommandState
    }
}

export default useCommandState