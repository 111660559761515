import {makeStyles} from '@mui/styles'
import {Theme} from '@mui/material'

export default makeStyles((theme:Theme) => ({
    settingsWrapper: {},
    drawer: {
        height: '95vh !important'
    },
    drawerDesktop: {
        '&.MuiPaper-root': {
            width: '20%',
            paddingTop: '41px',
            '& .MuiBox-root': {
                top: '3px'
            }
        }
    },
    title: {
        paddingTop: '10px',
        paddingBottom: '10px',
        textAlign: 'center'
    },
    listItem: {
        borderBottom: `1px solid ${theme.palette.grey.A400}`,
        '&.MuiButtonBase-root': {
            borderBottom: `1px solid ${theme.palette.grey.A400}`
        }
    },
    backIcon: {
        background: 'none',
        border: 'none',
        position: 'absolute',
        left: '5px',
        top: '5px',
        '&:hover': {
            cursor: 'pointer'
        }
    },
    header: {},
    avatar: {
        maxWidth: '30px',
        height: '20px',
    },
    gridWrapper: {
        '& .MuiListItem-root': {
            '&:hover': {
                cursor: 'pointer',
                background: theme.palette.grey['200']
            }
        },
        '& .MuiButtonBase-root': {
            '&:hover': {
                background: 'none'
            }
        }
    }
}))