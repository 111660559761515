import React from 'react'
import useStyles from './styles'
import {useTranslation} from "react-i18next"
import {Box, CircularProgress, Fab, IconButton, Typography} from "@mui/material"
import useCurrentStates from "../../../../hooks/useCurrentStates"
import {getCurrentStateText, getTimeIndicationPercentage} from "../../../../utils"
import {Bolt, Warning} from "@mui/icons-material"
import useToggleCurrentStates from "../../../../hooks/useToggleCurrentState"
import usePendingChargingState from "../../../../hooks/pendingChargingState";
import initializingIcon from "../../../../assets/icons/initializing.svg"
const CurrentState = () => {
    const classes = useStyles()
    const {t} = useTranslation()
    const {currentStates} = useCurrentStates()
    const {setToggleCurrentStates} = useToggleCurrentStates()
    const {pendingChargingState} = usePendingChargingState()
    return (
        pendingChargingState ? (
                <>
                    <IconButton
                        edge="start"
                        color="inherit"
                        aria-label={t('locationMap.initializing')}
                        className={`${classes.clusterText} ${classes.initializingIcon}`}
                    >
                        <img src={initializingIcon} alt={t('locationMap.initializing')}/>
                    </IconButton>
                </>
            ) : (
            <>
                <Box className={classes.clusterText}
                     position="relative"
                     display="flex"
                     boxShadow={2}
                     borderRadius="50%"
                     onClick={() => {setToggleCurrentStates(true)}}
                >
                    {
                        typeof currentStates[0]?.no_communication !== 'undefined' && currentStates[0]?.no_communication ? (
                            <>
                                <Fab aria-label="save"
                                     className={classes.fab}
                                >
                                    <Warning/>
                                </Fab>
                            </>
                        ) : typeof currentStates[0]?.measurements !== 'undefined' && currentStates[0]?.measurements['state_of_charge'] ? (
                            <>
                                <CircularProgress variant="determinate"
                                                  className={classes.bottom}
                                                  thickness={4}
                                                  value={100}
                                />
                                <CircularProgress variant="determinate"
                                                  thickness={4}
                                                  value={currentStates[0]?.measurements['state_of_charge']}
                                                  className={classes.top}
                                />
                            </>
                        ) : typeof currentStates[0]?.measurements !== 'undefined' && currentStates[0]?.session['server_time'] ? (
                            <>
                                <CircularProgress variant="determinate"
                                                  className={classes.bottom}
                                                  thickness={4}
                                                  value={100}
                                />
                                <CircularProgress variant="determinate"
                                                  thickness={4}
                                                  value={getTimeIndicationPercentage(currentStates[0]?.price)}
                                                  className={classes.top}
                                />
                            </>
                        ) : typeof currentStates[0]?.measurements !== 'undefined' && currentStates[0]?.session['charging_from_utc'] ? (
                            <>
                                <CircularProgress variant="determinate"
                                                  className={classes.bottom}
                                                  thickness={4}
                                                  value={100}
                                />
                                <CircularProgress variant="determinate"
                                                  thickness={4}
                                                  value={getTimeIndicationPercentage(currentStates[0]?.price)}
                                                  className={classes.top}
                                />
                            </>
                        ) : (
                            <Bolt className={classes.blueColor}/>
                        )
                    }
                    <Box top={0}
                         left={0}
                         bottom={0}
                         right={0}
                         position="absolute"
                         display="flex"
                         alignItems="center"
                         justifyContent="center"
                    >
                        {
                            currentStates[0] && (
                                <Typography className={classes.countNumber}>
                                    <strong>
                                        {
                                            currentStates?.length && (
                                                <span>
                                                {getCurrentStateText(currentStates[0], t)}
                                            </span>
                                            )
                                        }
                                    </strong>
                                </Typography>
                            )
                        }

                    </Box>
                </Box>
            </>
            )
    )
}

export default CurrentState
