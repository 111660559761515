import {useDispatch, useSelector} from 'react-redux'
import {setFieldValueOfLocation} from '../store/features/Location'
import {RootState} from './../store'

const useToggleSearchResultsList = () => {
    const dispatch = useDispatch()
    const toggleSearchResultsList = useSelector((state: RootState) => state.location.toggleSearchResultsList)
    const setToggleSearchResultsList = (value) => {
        dispatch(setFieldValueOfLocation({
            field: 'toggleSearchResultsList',
            value
        }))
    }

    return {
        toggleSearchResultsList,
        setToggleSearchResultsList
    }
}

export default useToggleSearchResultsList