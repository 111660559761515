import {useDispatch, useSelector} from 'react-redux'
import {setFieldValueOfLocation} from '../store/features/Location'
import {RootState} from './../store'

const useToggleMetricsDialog = () => {
    const dispatch = useDispatch()

    const toggleMetricsDialog = useSelector((state: RootState) => state.location.toggleMetricsDialog)
    const setToggleMetricsDialog = (value: boolean) => {
        dispatch(setFieldValueOfLocation({
            field: 'toggleMetricsDialog',
            value
        }))
    }

    return {
        toggleMetricsDialog,
        setToggleMetricsDialog
    }
}

export default useToggleMetricsDialog