import {makeStyles} from '@mui/styles'
import {Theme} from '@mui/material'
import { blue } from '@mui/material/colors'

export default makeStyles((theme:Theme) => ({
    recenterWrapperDesktop: {
        zIndex: 0,
        width: '50px',
        position: 'absolute',
        bottom: '150px',
        left: '1vw'
    },
    recenterWrapper: {
        zIndex: 0,
        width: '50px',
        left: '2vw',
        '&.MuiBox-root': {
            position: 'absolute',
            bottom: '2vh'
        }
    },
    top: {
        width: '50px',
        height: '50px',
        animationDuration: '550ms',
        position: 'absolute',
        '&.MuiCircularProgress-root': {
            color: theme.palette.secondary.main
        }
    },
    bottom: {
        color: theme.palette.grey[700],
        '&.MuiCircularProgress-root': {
            color: blue[100]
        }
    },
    countNumber: {
        color: theme.palette.grey[700]
    },
    blueColor: {
        color: theme.palette.secondary.main
    },
    fab: {
        '&.MuiFab-root': {
            background: theme.palette.common.white,
            transform: 'scale(1)',
            color: theme.palette.grey[700],
            height: '50px',
            '&:hover': {
                background: theme.palette.grey[100],
            }
        }
    }
}))