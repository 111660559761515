import {useDispatch, useSelector} from 'react-redux'
import {setFieldValueOfLocation} from '../store/features/Location'
import {RootState} from './../store'

const useSearch = () => {
    const dispatch = useDispatch()

    const search = useSelector((state: RootState) => state.location.search)
    const setSearch = (value: string) => {
        dispatch(setFieldValueOfLocation({
            field: 'search',
            value
        }))
    }

    return {
        search,
        setSearch
    }
}

export default useSearch