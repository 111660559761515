import {createSlice} from '@reduxjs/toolkit'

const locationSlice = createSlice({
    name: 'users',
    initialState: {
        currentStates: [],
        commandState: null,
        pendingChargingState: false,
        lastUsedConnector: null,
        isLoadMoreVisible: true,
        locationsList: null,
        locationListPage: 1,
        locationListLimit: 20,
        place: {},
        placesList: null,
        toggleOpeningHours: false,
        toggleAccessInstructions: false,
        toggleGallery: false,
        toggleSearchResultsList: false,
        toggleScanQR: false,
        toggleSearchLocation: false,
        toggleCurrentState: false,
        toggleMetricsDialog: false,
        search: ''
    },
    reducers: {
        setFieldValueOfLocation: (state, action) => {
            // @ts-ignore
            state[action.payload.field] = action.payload.value
        }
    }
})

export const {setFieldValueOfLocation} = locationSlice.actions
export default locationSlice.reducer