import {makeStyles} from '@mui/styles'
import {Theme} from '@mui/material'

export default makeStyles((theme:Theme) => ({
    userMenuWrapper: {},
    userMenuWrapperDesktop: {
        position: 'absolute',
        top: '10px',
        left: '4.5vw',
        '& .MuiPaper-root': {
            width: '350px'
        }
    },
    userMenuWrapperMobile: {
        position: 'absolute',
        top: '1vh',
        left: '5vw',
        '& .MuiPaper-root': {
            minWidth: '100%',
            width: '100%',
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0
        }
    },
    button: {
        border: 'none',
        background: 'none'
    },
    layerIcon: {
        '&.MuiFab-root': {
            background: theme.palette.common.white,
            top: '2vw',
            right: '2vw'
        },
        '& .MuiSvgIcon-root': {
            color: theme.palette.grey[700]
        }
    },
    title: {
        paddingTop: '10px',
        paddingBottom: '10px',
        textAlign: 'center'
    },
    listItem: {
        borderBottom: `1px solid ${theme.palette.grey.A400}`,
        '&.MuiButtonBase-root': {
            borderBottom: `1px solid ${theme.palette.grey.A400}`
        }
    },
    drawerDesktop: {
        '&.MuiPaper-root': {
            width: '30%',
            paddingTop: '41px',
            '& .MuiBox-root': {
                top: '3px'
            }
        }
    },
    drawer: {
        height: '95vh !important'
    },
    header: {},
    closeIcon: {
        background: 'none',
        border: 'none',
        position: 'absolute',
        right: '5px',
        top: '5px',
        '&:hover': {
            cursor: 'pointer'
        }
    },
    cancelSearchButton: {
        color: theme.palette.grey[500]
    },
    selectedPlaceCancelButton: {
        opacity: 0.9,
        color: `${theme.palette.secondary.light} !important`,
        '&:hover': {
            cursor: 'pointer',
            opacity: 1
        }
    },
    iconStyle: {
        marginTop: '4px !important'
    },
    searchInputFieldDesktop: {
        '& .MuiInputBase-input': {
            padding: 0,
            width: '200px'
        }
    },
    searchInputFieldMobile: {
        '& .MuiInputBase-input': {
            padding: 0,
            width: '45vw'
        }
    },
    clientZoneInputField: {
        paddingLeft: '10px'
    },
    clientZoneSearchWrapper: {
        '& .MuiPaper-root': {
            width: '100%'
        }
    },
    loadingButton: {
        '& .MuiSvgIcon-root': {
            opacity: 0.5,
            color: theme.palette.grey["500"]
        }
    },
    versionWrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%'
    }
}))