import {createSlice} from '@reduxjs/toolkit'

const basicSlice = createSlice({
    name: 'users',
    appVersion: null,
    initialState: {
        isLoading: false,
        isMapLoading: false,
        filterChanged: 0,
        languageSettings: false,
        selectedLanguages: 'en'
    },
    reducers: {
        setFieldValueOfBasic: (state, action) => {
            state[action.payload.field] = action.payload.value
        }
    }
})

export const {setFieldValueOfBasic} = basicSlice.actions
export default basicSlice.reducer