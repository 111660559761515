import {useDispatch, useSelector} from 'react-redux'
import {setFieldValueOfBasic} from '../store/features/Basic'
import {RootState} from './../store'

const useLanguageSettings = () => {
    const dispatch = useDispatch()
    const languageSettings = useSelector((state: RootState) => state.basic.languageSettings)
    const setLanguageSettings = (value) => {
        dispatch(setFieldValueOfBasic({
            field: 'languageSettings',
            value
        }))
    }

    return {
        languageSettings,
        setLanguageSettings
    }
}

export default useLanguageSettings