import { makeStyles } from '@mui/styles';
import {Theme} from '@mui/material'

export default makeStyles((theme:Theme) => ({
    clusterWrapper: {
        width: '50px',
        height: '50px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginLeft: '-10px',
        marginTop: '-15px'
    },
    cluster: {
        padding: 0,
        margin: 0,
        width: '45px',
        height: '45px',
        color: 'black',
        borderRadius: '50%',
        background: 'rgba(255, 255, 255, 0.75)',
        backdropFilter: 'blur(7.99915px)',
        zIndex: 1,
        '&:hover': {
            zIndex: 2,
            cursor: 'pointer',
            transform: 'scale(1.1)'
        }
    },
    clusterText: {
        fontFamily: 'Roboto',
        fontSize: '1.5rem',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '45px',
        fontWeight: 'bold'
    },
    top: {
        animationDuration: '550ms',
        position: 'absolute'
    },
    bottom: {
        color: `${theme.palette.grey[300]} !important`
    },
    countNumber: {
        color: theme.palette.grey[700]
    }
}));