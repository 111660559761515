import {RootState} from './../store'
import {useDispatch, useSelector} from 'react-redux'
import {setFieldValueOfMap} from "../store/features/Map";

const useDrawerOpen = () => {
    const dispatch = useDispatch()
    const drawerOpen = useSelector((state: RootState) => state.map.drawerOpen)
    const setDrawerOpen = (value: boolean | null) => {
        dispatch(setFieldValueOfMap({
            field: 'drawerOpen',
            value
        }))
    }

    return {
        drawerOpen,
        setDrawerOpen
    }
}

export default useDrawerOpen