import React from 'react'
import useStyles from './styles'
import Pin from './../../../../assets/pins/place.svg'

function PinPlace({selectedPlace}) {
    const classes = useStyles()
    return (
        <div className={classes.markerContainer}
             lat={Number(selectedPlace.latitude)}
             lng={Number(selectedPlace.longitude)}
        >
            <img src={Pin} className={classes.pinImage} alt=""/>
        </div>
    )
}

export default PinPlace