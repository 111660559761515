import { makeStyles } from '@mui/styles';
import {Theme} from '@mui/material'

export default makeStyles((theme:Theme) => ({
    currentLocationWrapper: {
        display: 'inline-flex',
        textAlign: 'center',
        alignItems: 'center',
        justifyContent: 'center',
        transform: 'translate(-50%, -50%)'
    }
}))