import {RootState} from './../store'
import {useDispatch, useSelector} from 'react-redux'
import {setFilterValue, resetFilterOptions} from '../store/features/Filter'
import {getDefaultState} from '../store/features/Filter/helper'

const useFilterOptions = () => {
    const dispatch = useDispatch()
    const filterOptions = useSelector((state: RootState) => state.filter.filterOptions)
    const defaultFilters = getDefaultState()

    const filterHasChanges = () => {
        return !!(JSON.stringify(defaultFilters) !== JSON.stringify(filterOptions))
    }

    const setFilterOptions = (field, value) => {
        dispatch(setFilterValue({
            field,
            value
        }))
    }

    const resetToDefault = () => {
        dispatch(resetFilterOptions())
    }

    return {
        defaultFilters,
        filterOptions,
        filterHasChanges,
        resetToDefault,
        setFilterOptions
    }
}

export default useFilterOptions