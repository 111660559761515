import {useDispatch, useSelector} from 'react-redux'
import {setFieldValueOfBasic} from '../store/features/Basic'
import {RootState} from './../store'

const useFilterChanged = () => {
    const dispatch = useDispatch()
    const filterChanged = useSelector((state: RootState) => state.basic.filterChanged)
    const setFilterChanged = (value) => {
        dispatch(setFieldValueOfBasic({
            field: 'filterChanged',
            value
        }))
    }

    return {
        filterChanged,
        setFilterChanged
    }
}

export default useFilterChanged