import {makeStyles} from '@mui/styles'
import {alpha, Theme} from '@mui/material'

// @ts-ignore
export default makeStyles((theme: Theme) => ({
    mapControllerWrapper: {
        position: 'absolute',
        top: '5px',
        right: '5px',
        zIndex: 0
    },
    mapControllerWrapperMobile: {
        position: 'absolute',
        bottom: '13.5vh',
        right: '1vh',
        zIndex: 0
    },
    button: {
        border: 'none',
        background: 'none'
    },
    layerIcon: {
        '&.MuiFab-root': {
            background: theme.palette.common.white,
            top: '0.5vw',
            right: '0.5vw'
        },
        '& .MuiSvgIcon-root': {
            color: theme.palette.grey[700]
        }
    },
    drawer: {
        height: '285px !important'
    },
    title: {
        paddingTop: '10px',
        textAlign: 'center'
    },
    closeIcon: {
        background: 'none',
        border: 'none',
        position: 'absolute !important',
        right: '5px',
        top: '5px'
    },
    selected: {
        backgroundColor: `${alpha(theme.palette.secondary.light, 0.1)} !important`
    },
    divider: {
        paddingTop: '10px'
    },
    listWrapper: {
        overflow: 'auto'
    }
}))