import {useDispatch, useSelector} from 'react-redux'
import {setFieldValueOfLocation} from '../store/features/Location'
import {RootState} from './../store'

const useIsLoadMoreVisible = () => {
    const dispatch = useDispatch()

    const isLoadMoreVisible = useSelector((state: RootState) => state.location.isLoadMoreVisible)
    const setIsLoadMoreVisible = (value: Boolean) => {
        dispatch(setFieldValueOfLocation({
            field: 'isLoadMoreVisible',
            value
        }))
    }

    return {
        isLoadMoreVisible,
        setIsLoadMoreVisible
    }
}

export default useIsLoadMoreVisible