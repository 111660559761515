import {makeStyles} from '@mui/styles';
import {Theme} from '@mui/material'

// @ts-ignore
export default makeStyles((theme: Theme) => ({
    topSection: {},
    middleSection: {},
    bottomSection: {
        justifyContent: 'center',
        textAlign: 'center',
        marginBottom: '10px',
        '& .MuiButton-root': {
            width: '100%',
            padding: '10px 0',
            borderRadius: '25px',
            textTransform: 'capitalize',
            marginTop: '15px'
        }
    },
    wrapper: {
        '& .slick-track': {
            display: 'flex',
            alignItems: 'center'
        },
        '& .MuiPaper-root': {
            justifyContent: 'end'
        },
        '& .MuiDialogContentText-root': {
            marginTop: '20px',
            justifyContent: 'center',
            alignItems: 'center'
        },
        '& .MuiDialogTitle-root': {
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center'
        },
        '& .MuiTextField-root': {
            marginTop: '15px',
            width: '100%'
        }
    },
    closeButton: {
        '&.MuiButtonBase-root': {
            float: 'right'
        },
        '& .MuiSvgIcon-root': {
            fontSize: '2.5rem'
        }
    },
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        marginRight: theme.spacing(2),
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(3),
            width: 'auto'
        },
    },
    inputRoot: {
        color: 'inherit',
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {width: '20ch'},
    },
    searchIcon: {
        padding: theme.spacing(0, 2),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    list: {
        height: '75vh',
        overflow: 'auto',
    },
    listItem: {
        minHeight: '75px',
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        '& .MuiListItemButton-root': {
            padding: 0
        }
    },
    locationInfoWrapper: {
        lineHeight: 1.25
    },
    locationAdditionalInfoWrapper: {
        lineHeight: 1,
        '& .MuiSvgIcon-root': {
            fontSize: '1rem'
        }
    },
    greenColor: {
        color: theme.palette.primary.main
    },
    orangeColor: {
        color: theme.palette.warning.main
    },
    redColor: {
        color: theme.palette.error.main
    },
    greyColor: {
        color: theme.palette.grey[500]
    },
    ListItemText: {
        maxWidth: '70%'
    },
    ListItemSecondary: {
        right: 0,
        textAlign: 'right'
    },
    FontWeightBold: {
        fontWeight: `${theme.typography.fontWeightBold} !important`
    },
    avatar: {
        border: `1px solid ${theme.palette.grey[300]}`,
        '&.MuiAvatar-root': {
            borderRadius: '15px',
        }
    },
    maxPower: {
        color: theme.palette.common.black,
        '&.MuiTypography-root': {
            color: theme.palette.grey[700]
        }
    },
    code: {
        color: theme.palette.common.black
    },
    distance: {
        color: theme.palette.grey[700]
    },
    availability: {
        '&.MuiTypography-root': {
            fontSize: '0.875rem',
            fontWeight: theme.typography.fontWeightBold,
            lineHeight: 1,
            paddingBottom: '2px'
        }
    },
    titleWrapper: {
        paddingBottom: '2px'
    },
    title: {
        color: theme.palette.common.black,
        fontSize: '0.875rem',
        fontWeight: theme.typography.fontWeightBold,
        width: '70%',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        position: 'absolute'
    },
    loading: {
        textAlign: 'center',
        '&.MuiTypography-root': {
            marginTop: '25px'
        }
    },
    loadMore: {
        textAlign: 'center',
        '&.MuiButton-root': {
            marginTop: '20px',
            paddingBottom: '30px'
        }
    },
    noResult: {
        '&.MuiTypography-root': {
            marginTop: '20px',
            color: theme.palette.error.main,
            fontWeights: theme.typography.fontWeightBold,
            textAlign: 'center'
        }
    }
}));