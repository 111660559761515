import logo from './assets/logo.svg'
const config = {
    mapLogo: logo,
    languageFallback: 'sk',
    currentStateFetchTimeout: process.env.REACT_APP_CURRENT_STATE_FETCH_TIMEOUT || 60000,
    commandStateFetchTimeout: process.env.REACT_APP_COMMAND_STATE_FETCH_TIMEOUT || 60000,
    startChargingRedirectLink: process.env.REACT_APP_START_CHARGING_REDIRECT_URL || 'https://api-uat.greenway.sk/api/',
    stopChargingRedirectLink: process.env.REACT_APP_STOP_CHARGING_REDIRECT_URL || 'https://api-uat.greenway.sk/api/',
    chargingSessionLinkAPI: process.env.REACT_APP_CHARGING_SESSION_LINK_API || 'https://api-uat.greenway.sk/api/',
    filterOptions: {
        MAX_POWER: 350
    }
}
export default config