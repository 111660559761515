import React, {useEffect, useState} from "react"
import useStyles from "./styles"
import {useDebounce} from 'use-debounce'
import {cancelRequests, getConnectors} from '../../../../api'
import {ArrowForwardIos as ArrowForwardIosIcon, Search as SearchIcon, Close as CloseIcon} from '@mui/icons-material/'

import {
    Avatar,
    Button,
    CircularProgress,
    Dialog,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton, InputAdornment,
    List,
    ListItem,
    ListItemButton,
    ListItemAvatar,
    ListItemSecondaryAction,
    TextField,
    Typography
} from "@mui/material"

import {useInView} from "react-intersection-observer"
import useToggleSearchLocation from "../../../../hooks/useToggleSearchLocation"
import {useTranslation} from "react-i18next"
import {getClientZoneUrl, getParamValue} from "../../../../utils"

const SearchLocation = () => {
    const classes = useStyles()
    const {toggleSearchLocation, setToggleSearchLocation} = useToggleSearchLocation()
    const [value, setValue] = React.useState(null)
    const [debounceValue] = useDebounce(value, Number.parseInt(process.env.REACT_APP_MAP_DRAG_END_DEBOUNCE))
    const [options, setOptions] = React.useState([])
    const [page, setPage] = React.useState(1)
    const [noResults, setNoResults] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [showLoadMore, setShowLoadMore] = useState(true)
    const { t } = useTranslation()

    const {ref} = useInView({
        threshold: 0
    })

    // useEffect(() => {
    //     if (inView) {
    //         loadMore()
    //     }
    // }, [inView])

    useEffect(() => {
        setPage(1)
        setOptions([])
    }, [toggleSearchLocation])


    const loadMore = () => {
        setIsLoading(true)
        const nextPage = page + 1
        setPage(nextPage)
    }

    const fetchConnectors = () => {
        getConnectors(debounceValue, 20, page).then(results => {
            setIsLoading(true)

            if (typeof results !== 'undefined' && results.length) {
                setOptions([...options, ...results])
            } else {
                // because if you use pagination, and you are at the end of the results list
                // you still want to keep previous results
                if(page === 1) {
                    setShowLoadMore(false)
                    setNoResults(true)
                } else {
                    setOptions([...options])

                    if(!results.length) {
                        setShowLoadMore(false)
                    }
                }
            }
            setIsLoading(false)
        }).catch((e) => {
            console.log({e})
        })
    }

    useEffect(() => {
        cancelRequests()

        if(page <= 1) {
            setOptions([])
            setNoResults(false)
        }

        if (debounceValue && debounceValue.trim().length) {
            fetchConnectors()
        } else {
            setIsLoading(false)
        }
    }, [debounceValue, page])

    const getLabel = (item) => {
        return item.location.owner_type === 'Roaming' ? item.location.printed_label : `${item.location.code} | ${t('locationMap.connector')} ${item.printed_label}`
    }

    const getConnectorName = (type, t) => {
        switch (type) {
            case 'ConnectorType_CHAdeMO':
                return t('locationMap.chaDeMo')
            case 'ConnectorType_CCS':
                return t('locationMap.ccs')
            case 'ConnectorType_Type2Cable':
                return t('locationMap.type2c')
            case 'ConnectorType_Type2Socket':
                return t('locationMap.type2')
            case 'ConnectorType_HomeDe':
            default:
                return t('locationMap.unknown')
        }
    }

    return (
        <div>
            {
                <Dialog open={toggleSearchLocation}
                        fullScreen={true}
                        className={classes.wrapper}
                >
                    <DialogTitle>
                        <Grid container
                              justifyContent="center"
                              alignItems="center"
                        >
                            <Grid item xs={10}>
                                <Typography variant={"h6"}>
                                    {t('locationMap.typeChargerQRCode')}
                                </Typography>
                            </Grid>
                            <Grid item xs={2} className={classes.topSection}>
                                <IconButton
                                    edge="start"
                                    color="inherit"
                                    onClick={() => {
                                        setToggleSearchLocation(false)
                                    }}
                                    aria-label="close"
                                    className={classes.closeButton}
                                >
                                    <CloseIcon/>
                                </IconButton>
                            </Grid>
                        </Grid>
                    </DialogTitle>
                    <DialogContent>
                        <Grid container>
                            <Grid item xs={12} className={classes.middleSection}>
                                <TextField
                                    autoComplete="off"
                                    label={t('locationMap.searchInput')}
                                    onChange={(event) => {
                                        setPage(1)
                                        setOptions([])
                                        setShowLoadMore(true)
                                        setValue(event.currentTarget.value)
                                    }}
                                    InputProps={{
                                        type: 'text',
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <SearchIcon/>
                                            </InputAdornment>
                                        )
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                {
                                    options.length > 0 && (
                                        <List dense>
                                            {
                                                options?.map((item, i) => (
                                                    <ListItem key={`${item.location['location_id']}-${i}`}
                                                              className={classes.listItem}
                                                              onClick={() => {
                                                                  const redirectLink = getParamValue('startChargingRedirectLink') || `${getClientZoneUrl(window.location.origin)}/charging?connectorId=${item.connector_id}`
                                                                  window.open(redirectLink, '_top')
                                                              }}
                                                              sx={{paddingLeft: 0, padding: '0', margin: '10px 0'}}
                                                    >
                                                        <ListItemButton>
                                                            <ListItemAvatar>
                                                                <Avatar alt={item.location.name}
                                                                        src={item.location['default_photo']}
                                                                        variant="rounded"
                                                                        sx={{
                                                                            width: 60,
                                                                            height: 60,
                                                                            marginRight: '15px'
                                                                        }}
                                                                        className={classes.avatar}
                                                                />
                                                            </ListItemAvatar>

                                                            <div className={classes.locationInfoWrapper}>
                                                                <Typography variant={'subtitle1'}
                                                                            className={classes.titleWrapper}>
                                                                    <span className={classes.title}>{item.location['name']}</span>
                                                                </Typography>
                                                                <br/>
                                                                {
                                                                    item && (
                                                                        <Typography variant={'caption'}
                                                                                    className={classes.code}>
                                                                            {getLabel(item)}
                                                                        </Typography>
                                                                    )
                                                                }

                                                                <br/>
                                                                <Typography variant={'caption'}
                                                                            className={classes.maxPower}>
                                                                    {getConnectorName(item['type'], t)}, {item['max_power']}kW
                                                                </Typography>
                                                            </div>
                                                            <ListItemSecondaryAction
                                                                className={classes.ListItemSecondary}
                                                                sx={{right: 0, lineHeight: 1}}
                                                            >
                                                                <div className={classes.locationAdditionalInfoWrapper}>
                                                                    <IconButton edge="end">
                                                                        <ArrowForwardIosIcon/>
                                                                    </IconButton>
                                                                </div>
                                                            </ListItemSecondaryAction>
                                                        </ListItemButton>
                                                    </ListItem>
                                                ))
                                            }
                                        </List>
                                    )
                                }

                                {
                                    noResults && (
                                        <Typography variant={'subtitle2'} className={classes.noResult}>
                                            {t('locationMap.noSearchResults')}
                                        </Typography>
                                    )
                                }

                                {
                                    options.length && !isLoading && showLoadMore ?
                                        (
                                            <Grid item xs={12}
                                                  className={classes.loadMore}
                                            >
                                                <Button variant="text"
                                                        onClick={() => {
                                                            loadMore()
                                                        }}
                                                        ref={ref}
                                                >
                                                    Load more
                                                </Button>
                                            </Grid>
                                        ) : (isLoading && !noResults && (
                                                <Grid item xs={12}>
                                                    <Typography variant="subtitle1"
                                                                className={classes.loading}
                                                    >
                                                        <CircularProgress
                                                            sx={{
                                                                width: '20px !important',
                                                                height: '20px !important'
                                                            }}
                                                            color="primary"
                                                        />
                                                    </Typography>
                                                </Grid>
                                            )
                                        )
                                }
                            </Grid>
                        </Grid>
                    </DialogContent>
                </Dialog>
            }
        </div>
    )
}

export default SearchLocation