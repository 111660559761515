import {RootState} from './../store'
import {useDispatch, useSelector} from 'react-redux'
import {setFieldValueOfFilter} from '../store/features/Filter'

const useFilterOptions = () => {
    const dispatch = useDispatch()
    const toggleFilter = useSelector((state: RootState) => state.filter.toggleFilter)
    const setToggleFilter = (value: boolean) => {
        dispatch(setFieldValueOfFilter({
            field: 'toggleFilter',
            value
        }))
    }

    return {
        toggleFilter,
        setToggleFilter
    }
}

export default useFilterOptions