import {
    SwipeableDrawer,
    CircularProgress,
    Grid
} from '@mui/material'
import useStyles from './styles'
import {getLocationDetail} from '../../api'
import React, {useEffect, useState} from 'react'

import moment from "moment"
import {RootState} from './../../store'
import {useDispatch, useSelector} from 'react-redux'
import {setFieldValueOfBasic} from '../../store/features/Basic'
import {setFieldValueOfLocation} from '../../store/features/Location'

import Box from "@mui/material/Box"
import {grey} from "@mui/material/colors"
import {styled} from "@mui/material/styles"
import CssBaseline from "@mui/material/CssBaseline"
import useMediaQuery from "@mui/material/useMediaQuery"

import Header from "./components/Header"
import Gallery from "./components/Gallery"
import TopSection from "./components/TopSection"
import CurrentState from "./components/CurrentState"
import OpeningHours from "./components/OpeningHours"
import LocationDetails from "./components/LocationDetails"
import ListOfConnectors from "./components/ListOfConnectors"
import {IOpeningHoursItem} from '../../interface/openingHours'
import AccessInstructions from "../Map/components/AccessInstructions"

import {useTranslation} from "react-i18next"
import useChildClicked from "../../hooks/useChildClicked"
import useCurrentStates from "../../hooks/useCurrentStates"
import useCoordinates from "../../hooks/useCoordinates"
import useHasChanges from "../../hooks/useHasChanges";

interface Props {
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    window?: () => Window
}

const Root = styled('div')(({theme}) => ({
    height: '100%',
    backgroundColor:
        theme.palette.mode === 'light' ? grey[100] : theme.palette.background.default,
}))

const StyledBox = styled(Box)(({theme}) => ({
    backgroundColor: theme.palette.mode === 'light' ? '#fff' : grey[800],
}))

const Puller = styled(Box)(({theme}) => ({
    width: 30,
    height: 6,
    backgroundColor: theme.palette.mode === 'light' ? grey[300] : grey[900],
    borderRadius: 3,
    position: 'absolute',
    top: 8,
    left: 'calc(50% - 15px)',
}))

const LocationDetail = (props: Props) => {
    const dispatch = useDispatch()
    const classes = useStyles()
    const {t} = useTranslation()
    const {window} = props
    const {setCoordinates} = useCoordinates()
    const isDesktop = useMediaQuery('(min-width: 900px)')
    const {childClicked, setChildClicked} = useChildClicked()
    const {setHasChanges} = useHasChanges()
    const drawerBleeding = isDesktop ? 0 : 55

    const toggleDrawer = (value: number | null) => () => {
        setChildClicked(value)
    }

    // get info if isCharging is set for this specific location
    // Current statuses
    const {currentStates} = useCurrentStates()

    const [isCharging, setIsCharging] = useState(false)
    useEffect(() => {
        if(typeof currentStates[0]?.location?.location_id !== 'undefined') {
            setIsCharging(currentStates.find(el => el.location?.location_id === childClicked) || null)
        }
    }, [childClicked])

    // Opening hours
    const getOpeningHoursInfo = (item: IOpeningHoursItem) => {
        if (item?.twenty_four_seven) {
            return t('locationMap.open24_7')
        }

        if (item?.open_now) {
            return item['changes_at'] ? `${t('locationMap.openUntil')} ${moment(item['changes_at'][0]).format('HH:mm')}` : t('locationMap.open')
        }

        return item['changes_at'] ? `${t('locationMap.openFrom')} ${moment(item['changes_at'][0]).format('HH:mm')}` : t('locationMap.closed')
    }

    // This is used only for the example
    const container = window !== undefined ? () => window().document.body : undefined

    // Location - place
    const place = useSelector((state: RootState) => state.location.place)
    const setPlace = (value) => {
        dispatch(setFieldValueOfLocation({
            field: 'place',
            value
        }))
    }

    // Basic - isLoading
    const isLoading = useSelector((state: RootState) => state.basic.isLoading)
    const setIsLoading = (value) => {
        dispatch(setFieldValueOfBasic({
            field: 'isLoading',
            value
        }))
    }

    const [openingHoursInfo, setOpeningHoursInfo] = useState('-')
    useEffect(() => {
        if (childClicked && childClicked !== place?.location_id) {
            setIsLoading(true)
            getLocationDetail(childClicked)
                .then(data => {
                    setPlace(data)
                    setOpeningHoursInfo(getOpeningHoursInfo(data))
                    setCoordinates({
                        lat: data.latitude,
                        lng: data.longitude
                    })
                })
                .finally(() => {
                    setIsLoading(false)
                    setHasChanges(true)
                })
        }
    }, [childClicked])

    const closeDrawer = () => {
        setPlace(null)
        toggleDrawer(null)
    }

    return (
        <div className={classes.container}>
            {
                currentStates && (
                    <CurrentState />
                )
            }
            {
                childClicked && place?.location_id && (
                    <>
                        {
                            place.photos?.length && (
                                <Gallery photos={place['photos']}/>
                            )
                        }
                        <OpeningHours openingHours={place['opening_hours']}/>
                        <AccessInstructions accessInstructions={place['access_instructions']} photos={place['photos']}/>
                        <Root>
                            <CssBaseline/>
                            <SwipeableDrawer
                                container={container}
                                anchor={isDesktop ? 'right' : 'bottom'}
                                PaperProps={
                                    {
                                        style: {
                                            width: isDesktop ? '45%' : '100%',
                                            height: isDesktop? '' : 'calc(98% - 105px)',
                                            overflow: isDesktop ? '' : 'visible'
                                        }
                                    }
                                }
                                open={childClicked}
                                onOpen={() => {}}
                                onClose={closeDrawer}
                                swipeAreaWidth={drawerBleeding}
                                disableSwipeToOpen={false}
                                ModalProps={{
                                    keepMounted: false,
                                }}
                            >
                                <StyledBox
                                    sx={{
                                        position: 'absolute',
                                        top: -drawerBleeding,
                                        borderTopLeftRadius: 16,
                                        borderTopRightRadius: 16,
                                        visibility: 'visible',
                                        right: 0,
                                        left: 0,

                                    }}
                                >
                                    <Puller/>
                                    <Header place={place}
                                            isLoading={isLoading}
                                            isCharging={isCharging}
                                    />
                                </StyledBox>

                                {
                                    isLoading || !childClicked ? (
                                        <div className={classes.loading}>
                                            <CircularProgress size="5rem"/>
                                        </div>
                                    ) : (
                                        <>
                                            <Grid container className={classes.drawerContainer}>
                                                <TopSection place={place}
                                                            openingHoursInfo={openingHoursInfo}
                                                />
                                                <ListOfConnectors place={place}
                                                                  isCharging={isCharging}
                                                />
                                                <LocationDetails place={place}
                                                                 openingHoursInfo={openingHoursInfo}
                                                />
                                            </Grid>
                                        </>
                                    )
                                }
                            </SwipeableDrawer>
                        </Root>
                    </>
                )
            }
        </div>
    )
}

export default LocationDetail
