import {useSelector} from "react-redux";

const useLastUsedConnector = () => {

    const lastUsedConnector = useSelector(() => {
        const value = localStorage.getItem('gw_charging_last_connector') || null
        return value ? value : null
    })
    const setLastUsedConnector = (value: string) => {
        if(value === null) {
            localStorage.removeItem('gw_charging_last_connector')
        } else {
            localStorage.setItem('gw_charging_last_connector', value)
        }
    }

    return {
        lastUsedConnector,
        setLastUsedConnector
    }
}

export default useLastUsedConnector