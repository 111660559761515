import React from 'react'
import useStyles from './styles'
import { useTranslation } from "react-i18next"
import { ChevronLeft } from "@mui/icons-material"
import config from '../../../../../../config.js'
import {
    ListItem,
    Drawer,
    Grid,
    ListItemIcon,
    ListItemText,
    Typography,
    Radio,
    IconButton
} from "@mui/material"
import useLanguageSettings from "../../../../../../hooks/useLanguageSettings"
import useSelectedLanguage from "../../../../../../hooks/useSelectedLanguage"
import useMediaQuery from "@mui/material/useMediaQuery"

const LanguageSettings = () => {
    const classes = useStyles()
    const {t} = useTranslation()
    const isDesktop = useMediaQuery('(min-width: 900px)')
    const {languageSettings, setLanguageSettings} = useLanguageSettings()
    const {selectedLanguage, setSelectedLanguage} = useSelectedLanguage()
    const lang = process.env.REACT_APP_I18N_LOCALE || config.languageFallback || 'sk'
    const {i18n} = useTranslation()

    return (
        <div className={classes.settingsWrapper}>
            <Drawer anchor={isDesktop ? 'left': 'bottom'}
                    open={languageSettings}
                    classes={{
                        paper: isDesktop ? classes.drawerDesktop : classes.drawer
                    }}
                    onClose={() => {
                        setLanguageSettings(false)
                    }}
            >
                <div className={classes.header}
                     onClick={() => {
                         setLanguageSettings(false)
                     }}
                >
                    <button className={classes.backIcon}>
                        <ChevronLeft/>
                    </button>
                    <Typography className={classes.title}
                                variant={'subtitle2'}
                    >
                        {t('locationMap.languageSettings')}
                    </Typography>
                </div>
                <Grid item xs={12} className={classes.gridWrapper}>
                    <ListItem
                        onClick={() => {
                            setSelectedLanguage('en')
                            localStorage.setItem('lang', 'en')
                            i18n.changeLanguage('en')
                            UC_UI.updateLanguage('en')
                        }}
                        secondaryAction={
                            <IconButton edge="end">
                                <img className={classes.avatar}
                                     src="https://www.geonames.org/flags/x/us.gif"
                                     alt="English"
                                />
                            </IconButton>
                        }
                    >
                        <ListItemIcon>
                            <Radio edge="start"
                                      tabIndex={-1}
                                      disableRipple
                                      checked={selectedLanguage === 'en'}
                            />
                        </ListItemIcon>

                        <ListItemText primary={`${t('locationMap.enOption')}`} />
                    </ListItem>
                    <ListItem
                        onClick={() => {
                            setSelectedLanguage(lang)
                            localStorage.setItem('lang', lang)
                            i18n.changeLanguage(lang)
                            UC_UI.updateLanguage(lang)
                        }}
                        secondaryAction={
                            <IconButton edge="end">
                                <img className={classes.avatar}
                                     src={`https://www.geonames.org/flags/x/${lang}.gif`}
                                     alt={lang}
                                />
                            </IconButton>
                        }
                    >
                        <ListItemIcon>
                            <Radio edge="start"
                                   checked={selectedLanguage === lang}
                                   tabIndex={-1}
                                   disableRipple
                            />
                        </ListItemIcon>

                        <ListItemText primary={`${t(`locationMap.${lang}Option`)}`} />
                    </ListItem>
                </Grid>
            </Drawer>
        </div>
    )
}

export default LanguageSettings
