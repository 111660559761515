import React, {useRef, useState} from 'react'
import useStyles from './styles'
import {useTranslation} from "react-i18next"
import {
    Divider,
    Drawer,
    Grid,
    IconButton,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Paper,
    InputBase,
    Typography
} from "@mui/material"
import {
    Close,
    Language,
    ChevronRight,
    FilterList,
    Public,
    MailOutline,
    Smartphone,
    Cookie,
    Menu as MenuIcon,
    Search as SearchIcon,
    Cancel
} from "@mui/icons-material"
import LanguageSettings from "./components/languageSettings"
import useLanguageSettings from "../../../../hooks/useLanguageSettings"
import {getParamValue} from "../../../../utils"
import useMediaQuery from "@mui/material/useMediaQuery"
import useToggleFilter from "../../../../hooks/useToggleFilter"
import useFilterChanged from "../../../../hooks/useFilterChanged"
import ListComponent from '../../../List'
import useSelectedPlace from "../../../../hooks/useSelectedPlace"
import useConfirmDialogPlaces from "../../../../hooks/useConfirmDialogPlaces"
import useSearch from "../../../../hooks/useSearch"
import {useSelector} from "react-redux"
import {RootState} from './../../../../store'
import useToggleSearchResultsList from "../../../../hooks/useToggleSearchResultsList"
import useAppVersion from "../../../../hooks/useAppVersion"

const UserMenu = () => {
    const classes = useStyles()
    const {t} = useTranslation()
    const [state, setState] = useState(false)
    const isDesktop = useMediaQuery('(min-width: 900px)')
    const {languageSettings, setLanguageSettings} = useLanguageSettings()
    const isIFrame = Boolean(getParamValue('isIframe')) || false
    const locale = process.env.REACT_APP_I18N_LOCALE || 'sk'
    const {filterChanged, setFilterChanged} = useFilterChanged()
    const {setToggleFilter} = useToggleFilter()

    // App version
    const {appVersion} = useAppVersion()

    const isLoading = useSelector((state: RootState) => state.basic.isLoading)

    const {search, setSearch} = useSearch()
    const {selectedPlace} = useSelectedPlace()
    const searchInput = useRef(null)
    const {setIsPlaceConfirmDialogOpen} = useConfirmDialogPlaces()
    const {toggleSearchResultsList} = useToggleSearchResultsList()
    const confirmCancelPlace = () => {
        setIsPlaceConfirmDialogOpen(true)
    }

    return (
        <>
            <div className={classes.userMenuWrapper}>
                {
                    languageSettings && (
                        <LanguageSettings/>
                    )
                }
                <Drawer anchor={isDesktop ? 'left': 'bottom'}
                        open={state}
                        onClose={() => {
                            setState(false)
                        }}
                        classes={{
                            paper: isDesktop ? classes.drawerDesktop : classes.drawer
                        }}
                >
                    <div className={classes.header}
                         onClick={() => {
                             setState(false)
                         }}
                    >
                        <button className={classes.closeIcon}>
                            <Close/>
                        </button>
                        <Typography className={classes.title}
                                    variant={'subtitle2'}
                        >
                            {t('locationMap.menu')}
                        </Typography>
                    </div>
                    <Grid item xs={12}>
                        <List>
                            <ListItem className={classes.listItem}
                                      secondaryAction={
                                          <IconButton edge="end">
                                              <ChevronRight/>
                                          </IconButton>
                                      }
                                      onClick={() => {
                                          setLanguageSettings(true)
                                      }}
                                      button
                            >
                                <ListItemIcon>
                                    <Language />
                                </ListItemIcon>
                                <ListItemText primary={t('locationMap.languageSettings')}/>
                            </ListItem>
                            <ListItem className={classes.listItem}
                                      secondaryAction={
                                          <IconButton edge="end">
                                              <ChevronRight/>
                                          </IconButton>
                                      }
                                      button
                                      component="a"
                                      target="_blank"
                                      href={t(`locationMap.clientZonePortalUrl_${locale}`)}
                            >
                                <ListItemIcon>
                                    <Public />
                                </ListItemIcon>
                                <ListItemText primary={t(`locationMap.clientZone`)}/>
                            </ListItem>
                            <ListItem className={classes.listItem}
                                      secondaryAction={
                                          <IconButton edge="end">
                                              <ChevronRight/>
                                          </IconButton>
                                      }
                                      button
                                      component="a"
                                      target="_blank"
                                      href={t(`locationMap.greenWayWebsiteUrl_${locale}`)}
                            >
                                <ListItemIcon>
                                    <Public />
                                </ListItemIcon>
                                <ListItemText primary={t(`locationMap.greenWayWebsite`)}/>
                            </ListItem>
                            <ListItem className={classes.listItem}
                                      secondaryAction={
                                          <IconButton edge="end">
                                              <ChevronRight/>
                                          </IconButton>
                                      }
                                      button
                                      key="Email"
                                      component="a"
                                      target="_blank"
                                      href={`mailto:${t(`locationMap.emailAddress_${locale}`)}`}
                            >
                                <ListItemIcon>
                                    <MailOutline />
                                </ListItemIcon>
                                <ListItemText primary={t(`locationMap.emailAddress_${locale}`)}/>
                            </ListItem>
                            <ListItem className={classes.listItem}
                                      secondaryAction={
                                          <IconButton edge="end">
                                              <ChevronRight/>
                                          </IconButton>
                                      }
                                      button
                                      key="Phone"
                                      component="a"
                                      target="_blank"
                                      href={`tel:${t(`locationMap.phoneNumber_${locale}`)}`}
                            >
                                <ListItemIcon>
                                    <Smartphone />
                                </ListItemIcon>
                                <ListItemText primary={t(`locationMap.phoneNumber_${locale}`)}/>
                            </ListItem>
                            <ListItem className={classes.listItem}
                                      button
                                      key="Privacy"
                                      component="a"
                                      target="_blank"
                                      onClick={() =>{
                                          UC_UI.showSecondLayer()
                                      }}
                            >
                                <ListItemIcon>
                                    <Cookie />
                                </ListItemIcon>
                                <ListItemText primary={t(`locationMap.privacySettings`)}/>
                            </ListItem>
                        </List>
                        <div className={classes.versionWrapper}>
                            <Typography variant="caption" className={classes.title}>
                                {t('locationMap.version')}: {appVersion}
                            </Typography>
                        </div>
                    </Grid>
                </Drawer>
            </div>
            <div className={`${isIFrame ? classes.clientZoneSearchWrapper : ''} ${isDesktop ? classes.userMenuWrapperDesktop : classes.userMenuWrapperMobile}`}>
                <Paper className={classes.layerIcon}
                       component="form"
                       elevation={3}
                       sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: 400 }}
                >
                    {
                        !isIFrame && (
                            <IconButton sx={{ p: '10px' }}
                                        aria-label="menu"
                                        onClick={() => {
                                            setState(true)
                                        }}
                            >
                                <MenuIcon />
                            </IconButton>
                        )
                    }
                    <InputBase
                        className={isIFrame ? classes.clientZoneInputField : ''}
                        classes={{root: isDesktop ? classes.searchInputFieldDesktop : classes.searchInputFieldMobile}}
                        size="small"
                        type="text"
                        ref={searchInput}
                        placeholder={t('locationMap.search')}
                        onChange={(event) => {
                            selectedPlace ? confirmCancelPlace() : setSearch(event.currentTarget.value)
                        }}
                        onKeyDown={(event) => {
                            if (event.key === 'Enter') {
                                selectedPlace ? confirmCancelPlace() : setSearch(event.currentTarget.value)
                                event.preventDefault()
                            }
                        }}
                        value={search}
                    />
                    {
                        isLoading ?
                            <IconButton type="button"
                                        aria-label="loading"
                                        sx={{ p: '10px' }}
                                        className={classes.loadingButton}
                            >
                                <SearchIcon />
                            </IconButton>
                            : (selectedPlace || toggleSearchResultsList ) ?
                                <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
                                    <Cancel className={`${classes.cancelSearchButton} ${selectedPlace ? classes.selectedPlaceCancelButton : ''}`}
                                            onClick={() => {
                                                confirmCancelPlace()
                                            }}
                                    />
                                </IconButton>
                            :
                            <IconButton type="button"
                                        sx={{ p: '10px' }}
                                        aria-label="search"
                                        onClick={(event) => {
                                            setSearch(search)
                                        }}
                            >
                                <SearchIcon />
                            </IconButton>
                    }
                    <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                    <IconButton color="primary" sx={{ p: '10px' }}
                                aria-label="directions"
                                onClick={() => {
                                    setToggleFilter(true)
                                    setFilterChanged(filterChanged+1)
                                }}
                    >
                        <FilterList />
                    </IconButton>
                </Paper>
                <ListComponent isSearching={isLoading} />
            </div>
        </>
    )
}

export default UserMenu
