import { makeStyles } from '@mui/styles';
import {Theme} from '@mui/material'

// @ts-ignore
export default makeStyles((theme: Theme) => ({
    'MuiFormControl-root': {
        margin: theme.spacing(1), minWidth: 120, marginBottom: '30px',
    },
    availabilityWrapper: {
        display: 'inline'
    },
    availabilityLeft: {
        float: 'left',
        paddingRight: '5px'
    },
    availabilityRight: {
        float: 'right'
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    loading: {
        height: '600px', display: 'flex', justifyContent: 'center', alignItems: 'center',
    },
    container: {
        maxHeight: '70vh',
        overflow: 'auto',
        marginTop: '1px',
        '& .MuiList-root': {
            paddingTop: 0
        }
    },
    marginBottom: {
        marginBottom: '30px',
    },
    list: {
        height: '75vh',
        overflow: 'auto',
    },
    listItemWrapper: {
        '& .MuiListItem-container': {
            paddingLeft: '5px',
            '&:hover': {
                cursor: 'pointer',
                backgroundColor: theme.palette.grey.A100
            }
        }
    },
    listItem: {
        maxWidth: '80%',
        minHeight: '75px',
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        padding: '0.5rem 0'
    },
    placesWrapper: {
        '&.MuiList-root': {
            padding: 0
        }
    },
    placeItemWrapper: {
        paddingLeft: '5px',
        '&:hover': {
            cursor: 'pointer',
            backgroundColor: theme.palette.grey.A100
        }
    },
    placeItem: {
        maxWidth: '100%',
        minHeight: '50px',
        borderBottom: `1px solid ${theme.palette.grey.A100}`,
        '& .MuiTypography-root': {
            display: 'flex',
            alignItems: 'center'
        }
    },
    placeTitle: {
        color: theme.palette.common.black,
        fontSize: '0.875rem',
        fontWeight: theme.typography.fontWeightRegular,
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        paddingLeft: '12px'
    },
    placeTitleSpan: {
        color: theme.palette.common.black,
        fontSize: '0.875rem',
        fontWeight: theme.typography.fontWeightBold,
        width: '85%',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        position: 'absolute'
    },
    locationOn: {
        color: theme.palette.grey.A400
    },
    locationInfoWrapper: {
      lineHeight: 1.25
    },
    locationAdditionalInfoWrapper: {
        lineHeight: 1,
        paddingTop: '4px'
    },
    greenColor: {
        color: theme.palette.primary.main
    },
    orangeColor: {
        color: theme.palette.warning.main
    },
    redColor: {
        color: theme.palette.error.main
    },
    greyColor: {
        color: theme.palette.grey[500]
    },
    blueColor: {
        color: theme.palette.secondary.main
    },
    ListItemText: {
        maxWidth: '70%'
    },
    ListItemSecondary: {
        right: 0,
        paddingRight: '10px',
        textAlign: 'right'
    },
    FontWeightBold: {
        fontWeight: `${theme.typography.fontWeightBold} !important`
    },
    avatarFallbackImage: {
        '& .MuiAvatar-img': {
            height: '75%',
            width: '65%'
        }
    },
    avatar: {
        border: `1px solid ${theme.palette.grey[300]}`,
        '&.MuiAvatar-root': {
            borderRadius: '15px'
        }
    },
    maxPower: {
        color: theme.palette.common.black,
        '&.MuiTypography-root': {
            lineHeight: 1.5
        }
    },
    ownerType: {
      color: theme.palette.common.black
    },
    openingHours: {
      color: theme.palette.grey[700]
    },
    distance: {
        color: theme.palette.grey[700]
    },
    availability: {
        '&.MuiTypography-root': {
            fontSize: '0.875rem',
            fontWeight: theme.typography.fontWeightBold,
            lineHeight: 1,
            paddingBottom: '2px'
        }
    },
    titleWrapper: {
      paddingBottom: '2px'
    },
    title: {
        color: theme.palette.common.black,
        fontSize: '0.875rem',
        fontWeight: theme.typography.fontWeightBold,
        width: '58%',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        position: 'absolute',
        [theme.breakpoints.down('sm')]: {
            width: '50%'
        }
    },
    isSearching: {
        opacity: 0.5
    },
    filterInputWrapper: {
        // display: 'flex',
        display: 'none',
        alignItems: 'center',
        justifyContent: 'start',
        paddingTop: '15px'
    },
    filterIconWrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '48px',
        height: '48px',
    },
    filterIcon: {
        backgroundColor: `${theme.palette.common.white} !important`,
        border: `1px solid ${theme.palette.text.secondary} !important`,
        color: `${theme.palette.text.primary} !important`,
        '& svg': {
            fontSize: '1.2rem'
        }
    },
    searchInputField: {
        width: '100%',
        '& .MuiOutlinedInput-root': {
            borderRadius: '25px',
            height: '40px'
        },
        '& input': {
            paddingTop: '10px'
        }
    },
    iconStyle: {
        marginTop: '4px !important'
    },
    cancelSearchButton: {
        color: theme.palette.grey[500]
    },
    selectedPlaceCancelButton: {
        color: theme.palette.secondary.light
    },
    wrapperSticky: {
        position: 'sticky',
        top: '0',
        background: 'white',
        height: '60px',
        zIndex: '1'
    },
    confirmDialogTitle: {
        fontSize: '1rem',
        textAlign: 'center',
        '&.MuiTypography-root': {
            fontWeight: theme.typography.fontWeightMedium
        }
    },
    confirmDialogContent: {
        '&.MuiDialogContent-root': {
            paddingBottom: '10px'
        }
    },
    confirmDialogText: {
        textAlign: 'center',
        '&.MuiTypography-root': {
            color: theme.palette.text.primary,
            fontWeight: theme.typography.fontWeightRegular
        }
    },
    confirmDialogActions: {
        '&.MuiDialogActions-root': {
            paddingBottom: '20px',
            justifyContent: 'center'
        }
    },
    confirmDialogCancelButton: {
        '&.MuiButtonBase-root': {
            color: theme.palette.grey.A700,
            borderColor: theme.palette.grey.A700,
            borderRadius: '25px',
            paddingTop: '7px',
            paddingBottom: '7px',
            paddingLeft: '20px',
            paddingRight: '20px'
        },
        '& .MuiTypography-root': {
            textTransform: 'capitalize',
            fontWeight: theme.typography.fontWeightMedium
        },
    },
    confirmDialogSubmitButton: {
        '&.MuiButtonBase-root': {
            borderRadius: '25px',
            paddingTop: '7px',
            paddingBottom: '7px',
            paddingLeft: '40px',
            paddingRight: '40px'
        },
        '& .MuiTypography-root': {
            textTransform: 'capitalize',
            fontWeight: theme.typography.fontWeightMedium
        },
    },
    loadingText: {
        textAlign: 'center'
    },
    toggledList: {
        opacity: 0.85,
        '&:hover': {
          opacity: 1
        },
        '& .MuiButtonBase-root': {
            height: '15px'
        },
    },
    toggleList: {
        textAlign: 'center',
        '& .MuiButtonBase-root': {
            height: '35px',
            borderRadius: 0,
            width: '100%',
            borderTop: `1px solid ${theme.palette.grey.A200}`
        },
        '& .MuiPaper-root': {
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
            borderBottomRightRadius: '5px',
            borderBottomLeftRadius: '5px'
        }
    }
}));