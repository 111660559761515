import React from 'react'
import useStyles from './styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import useToggleOpeningHours from '../../../../hooks/useToggleOpeningHours'
import moment from "moment"

import {
    Divider,
    Drawer,
    List,
    ListItem,
    ListItemText,
    Typography
} from '@mui/material'
import {useTranslation} from 'react-i18next'
import {CloseOutlined} from '@mui/icons-material'

function OpeningHours({openingHours}) {
    const classes = useStyles()
    const { t } = useTranslation()
    // This is used only for the example
    const isDesktop = useMediaQuery('(min-width: 900px)')
    const {toggleOpeningHours, setToggleOpeningHours} = useToggleOpeningHours()

    const isHighlighted = (item) => {
        if (item['is_public_holiday'] || item['week_day'].trim().toLowerCase() === 'saturday' || item['week_day'].trim().toLowerCase() === 'sunday') {
            return classes.subTitle
        }
        return ''
    }

    const formatDate = (date: string) => {
        return moment(date).format('D.M.YYYY')
    }

    const getOpeningHoursDays = (data) => {
        return (
            <>
                <ListItemText className={classes.locationDetailsInfoLeft}
                >
                    <Typography variant={'subtitle1'} className={isHighlighted(data)}>
                        { t(`locationMap.${data['week_day'].toLowerCase()}`) } ({formatDate(data.date)})
                    </Typography>
                </ListItemText>
            </>
        )
    }

    const getOpeningHours = (data) => {
        return (
            <>
                {
                    data['is_open'] ?
                        (
                            <div>
                            <span className={isHighlighted(data)}>
                                {
                                    data['is_open'] ? (
                                        <Typography variant={'subtitle1'}>
                                            {t('locationMap.open')}
                                        </Typography>
                                    ) : (
                                        <Typography variant={'subtitle1'}>
                                            {t('locationMap.closed')}
                                        </Typography>
                                    )
                                }
                            </span>
                            </div>
                        ) : (
                            <>
                                {
                                    data['opening_slots'] ? (
                                        <List>
                                            {
                                                data['opening_slots']?.map((value, index) => (
                                                    <ListItemText key={index}>
                                                        <Typography variant={'subtitle1'} className={isHighlighted(data)}>
                                                            {value['From']} - {value['To']}
                                                        </Typography>
                                                    </ListItemText>
                                                ))
                                            }
                                        </List>
                                    ) : (
                                        <Typography variant={'subtitle1'}>
                                            {t('locationMap.closed')}
                                        </Typography>
                                    )
                                }
                            </>
                        )
                }
            </>
        )
    }

    return (
        openingHours && (
            <Drawer anchor={isDesktop ? 'left' : 'bottom'}
                    PaperProps={{ style: { width: isDesktop ? '45%' : '100%' } }}
                    open={toggleOpeningHours}
                    onClose={() => {
                        setToggleOpeningHours(false)
                    }}
                    className={classes.container}
            >
                <div className={classes.closeButton}>
                    <CloseOutlined onClick={() => {
                        setToggleOpeningHours(false)
                    }}/>
                </div>
                <div className={classes.topSection}>
                    <Typography variant={'h6'}>
                        {t('locationMap.openingHours')}
                    </Typography>
                </div>
                <List component="nav" className={classes.locationDetailsInfo}>
                    <ListItem>
                        <ListItemText primary={t('locationMap.date')}
                                      className={`${classes.locationDetailsInfoLeft} ${classes.subTitle}`}/>
                        <ListItemText primary={t('locationMap.openingTime')}
                                      className={`${classes.locationDetailsInfoRight} ${classes.subTitle}`}/>
                    </ListItem>
                    <Divider/>
                    <div className={classes.openingHoursWrapper}>
                        {
                            openingHours.map((item, index) => (
                                <div key={index}>
                                    <ListItem key={index}>
                                        {getOpeningHoursDays(item)}
                                        {getOpeningHours(item)}
                                    </ListItem>
                                    <Divider light/>
                                </div>
                            ))
                        }
                    </div>
                </List>
            </Drawer>
        )
    )
}

export default OpeningHours