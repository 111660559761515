import React from 'react'
import QuestionMarkIcon from "@mui/icons-material/QuestionMark"
import {SvgIcon, Typography} from "@mui/material"
import {CheckCircleOutline, CloseOutlined, DoDisturb, Bolt} from "@mui/icons-material"
import useStyles from "./styles"
import {useDispatch} from 'react-redux'
import useChildClicked from "../../../../hooks/useChildClicked"
import {useTranslation} from "react-i18next";
import {getCurrentStateText} from "../../../../utils";
import {setFieldValueOfLocation} from "../../../../store/features/Location";


const Header = ({place, isLoading, isCharging}) => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const {setChildClicked} = useChildClicked()
    const {t} = useTranslation()

    // toggle drawer
    const toggleDrawer = (value: number | null) => () => {
        setChildClicked(value)
    }

    // get location status
    const getLocationStatus = (place) => {
        if(isCharging) {
            if(isCharging['no_communication']) {
                return classes['offline']
            }
            return classes['charging']
        }

        switch (place.availability?.toLowerCase()) {
            case 'available':
            case 'preparing':
            case 'finishing':
                return classes['available']
            case 'maintenance':
                return classes['error']
            case 'closed':
            case 'offline':
            case 'reserved':
            case 'newly_inserted':
            case 'occupied':
            case 'out_of_communication':
            case 'out_of_working_time':
            case 'suspended_evse':
            case 'suspended_ev':
            default:
                return classes['offline']
        }
    }

    // Get icon type
    const getIconType = (type) => {
        if(isCharging) {
            return Bolt
        }

        switch (type) {
            case 'available':
            case 'preparing':
            case 'finishing':
                return CheckCircleOutline
            case 'occupied':
            case 'offline':
            case 'closed':
            case 'maintenance':
            default:
                return DoDisturb
        }
    }

    const getChargingStatusInfo = (currentStatus) => {
        return `${t('locationMap.currentlyCharging')} (${getCurrentStateText(currentStatus, t)})`
    }

    const getStatusInfo = (place) => {
        if(isCharging) {
            return getChargingStatusInfo(isCharging)
        }

        switch (place.availability) {
            case 'available':
            case 'preparing':
            case 'finishing':
            case 'reserved':
            case 'newly_inserted':
            case 'occupied':
            case 'out_of_communication':
            case 'out_of_working_time':
            case 'suspended_evse':
            case 'suspended_ev':
                return `(${place['owner_type']}) ${t('locationMap.chargers')} (${place.available}/${place.total})`
            case 'offline':
                return t('locationMap.offline')
            case 'closed':
                return t('locationMap.closed')
            case 'maintenance':
                return t('locationMap.outOfOrder')
            default:
                return classes['offline']
        }
    }

    const setPlace = (value) => {
        dispatch(setFieldValueOfLocation({
            field: 'place',
            value
        }))
    }

    return (
            <div className={`${classes.topSection} ${isLoading ? classes.offline : getLocationStatus(place)}`}>
                <div className={classes.topLeftSection}>
                    {
                        isLoading ? (
                            <QuestionMarkIcon/>
                        ) : (
                            <SvgIcon component={getIconType(place.availability)} inheritViewBox/>
                        )
                    }
                    <Typography sx={{p: 2, color: 'text.secondary'}}>
                        {
                            isLoading ? (
                                t('locationMap.loading')
                            ) : (
                                getStatusInfo(place)
                            )
                        }
                    </Typography>
                </div>
                <div className={classes.topRightSection}>
                    <CloseOutlined onClick={
                        () => {
                            setPlace(null)
                            toggleDrawer(null)
                            }
                        }
                    />
                </div>
            </div>
    )
}

export default Header
