import {RootState} from './../store'
import {useDispatch, useSelector} from 'react-redux'
import {setFieldValueOfMap} from '../store/features/Map'

const useCoordinates = () => {
    const dispatch = useDispatch()
    const coordinates = useSelector((state:RootState) => state.map.coordinates)
    const setCoordinates = (value) => {
        dispatch(setFieldValueOfMap({
            field: 'coordinates',
            value
        }))
    }

    return {
        coordinates,
        setCoordinates
    }
}

export default useCoordinates