import {useDispatch, useSelector} from 'react-redux'
import {setFieldValueOfLocation} from '../store/features/Location'
import {RootState} from './../store'
import {ILocationsList} from './../interface/locationsList'

const useCurrentStates = () => {
    const dispatch = useDispatch()

    const currentStates = useSelector((state: RootState) => state.location.currentStates)
    const setCurrentStates = (value: ILocationsList | []) => {
        dispatch(setFieldValueOfLocation({
            field: 'currentStates',
            value
        }))
    }

    return {
        currentStates,
        setCurrentStates
    }
}

export default useCurrentStates