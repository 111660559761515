import React from 'react'
import useStyles from './styles'
import useMediaQuery from "@mui/material/useMediaQuery"
import useToggleAccessInstructions from "../../../../hooks/useToggleAccessInstructions"
import {Divider, Drawer, ImageListItem, List, ListItem, Typography} from '@mui/material'
import useToggleGallery from "../../../../hooks/useToggleGallery"
import {useTranslation} from "react-i18next"
import {CloseOutlined} from "@mui/icons-material"

import ReactMarkdown from 'react-markdown'

function AccessInstructions({accessInstructions, photos}) {
    const classes = useStyles()
    const {t} = useTranslation()

    // This is used only for the example
    const isDesktop = useMediaQuery('(min-width: 900px)')
    const {toggleAccessInstructions, setToggleAccessInstructions} = useToggleAccessInstructions()
    const {setToggleGallery} = useToggleGallery()

    return (
        accessInstructions && (
            <div className={classes.accessInstructionsWrapper}>
                <Drawer anchor={isDesktop ? 'left' : 'bottom'}
                        open={toggleAccessInstructions}
                        onClose={() => {
                            setToggleAccessInstructions(false)
                        }}
                        className={isDesktop ? classes.containerDesktop : classes.container}
                >
                    <div className={classes.closeButton}>
                        <CloseOutlined onClick={() => {
                            setToggleAccessInstructions(false)
                        }}/>
                    </div>
                    <div className={classes.topSection}>
                        <Typography variant={'h6'}>
                            {t('locationMap.accessInfo')}
                        </Typography>
                    </div>
                    <List component="nav">
                        <ul className={classes.contentWrapper}>
                            <ListItem key="access-instructions" className={classes.accessInstructions}>
                                <Typography gutterBottom variant={'subtitle1'}>
                                    <ReactMarkdown components={{
                                        a({ node, children, ...props }) {
                                            try {
                                                new URL(props.href ?? "");
                                                // If we don't get an error, then it's an absolute URL.

                                                props.target = "_blank";
                                                props.rel = "noopener noreferrer";
                                            } catch (e) {}

                                            return <a {...props}>{children}</a>;
                                        },
                                    }}>
                                        {accessInstructions}
                                    </ReactMarkdown>
                                </Typography>
                            </ListItem>
                            {
                                photos.map((photo, index) => (
                                    <div key={index}>
                                        <ImageListItem key={index}
                                                       cols={1}
                                                       rows={1}
                                                       className={classes.accessInstructions}
                                                       onClick={()=>{setToggleGallery(true)}}
                                        >
                                            <Divider variant="middle" className={classes.divider} />
                                            <div className={classes.imageWrapper}>
                                                <img src={photo.path}
                                                     alt={photo['short_description']}
                                                     loading="lazy"
                                                />
                                            </div>

                                            <Typography gutterBottom
                                                        variant={'subtitle1'}
                                                        className={classes.description}
                                            >
                                                {photo['description']}
                                            </Typography>
                                        </ImageListItem>
                                    </div>
                                ))
                            }
                        </ul>

                    </List>
                </Drawer>
            </div>
        )
    )
}

export default AccessInstructions