import {makeStyles} from '@mui/styles';
import {Theme} from '@mui/material'

// @ts-ignore
export default makeStyles((theme: Theme) => ({
    container: {
        width: '100%'
    },
    available: {
        color: theme.palette.primary.main
    },
    offline: {
        color: theme.palette.grey[700]
    },
    error: {
        color: theme.palette.error.main
    },
    warning: {
        color: theme.palette.warning.light
    },
    openingHours: {
        '&.MuiTypography-root': {
            fontWeight: `${theme.typography.fontWeightMedium}`,
            fontSize: '0.875rem'
        }
    },
    address: {
      color: theme.palette.grey[700]
    },
    title: {
        '&.MuiTypography-root': {
            maxWidth: '85%'
        }
    },
    avatar: {
        border: `1px solid ${theme.palette.grey[300]}`,
        '&.MuiAvatar-root': {
            borderRadius: '15px',
        },
        backgroundColor: theme.palette.grey[200]
    },
    actionButtonsBar: {
        paddingTop: '1rem',
        paddingBottom: '1rem',
        overflowX: 'auto',
        '& a': {
            textDecoration: 'none'
        }
    },
    accessInstructions: {
        fontSize: '0.875rem !important',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        WebkitLineClamp: 3,
        WebkitBoxOrient: 'vertical',
        display: '-webkit-box',
        '&.MuiTypography-root': {
            lineHeight: '1.5'
        }
    },
    topSection: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    topLeftSection: {
        display: 'flex',
        alignItems: 'center',
        paddingLeft: '1rem',
        paddingTop: '5px'
    },
    topRightSection: {
        display: 'flex',
        alignItems: 'center',
        paddingRight: '1rem',
        paddingTop: '5px'
    },
    button: {
        '&.MuiButtonBase-root': {
            transform: 'scale(1.5)',
            float: 'right',
            right: '3vw',
            top: '3vh'
        }
    },
    avatarFallbackImage: {
        '& .MuiAvatar-img': {
            height: '75%',
            width: '65%'
        }
    },
    badgeWrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    }
}));