import {useDispatch, useSelector} from 'react-redux'
import {setFieldValueOfMap} from '../store/features/Map'
import {RootState} from './../store'

const useToggleLocationsList = () => {
    const dispatch = useDispatch()
    const toggleLocationsList = useSelector((state: RootState) => state.map.toggleLocationsList)
    const setToggleLocationsList = (value) => {
        dispatch(setFieldValueOfMap({
            field: 'toggleLocationsList',
            value
        }))
    }

    return {
        toggleLocationsList,
        setToggleLocationsList
    }
}

export default useToggleLocationsList