import React from 'react'
import config from './../../../../config.js'
import useStyles from './styles'
import {useTranslation} from "react-i18next"
import {getParamValue} from "../../../../utils"

const Logo = () => {
    const classes = useStyles()
    const {t} = useTranslation()
    const logo = getParamValue('mapLogo') || config.mapLogo
    return (
        <div className={classes.logo}>
            <img src={logo} alt={t('locationMap.greenWay')}/>
        </div>
    )
}

export default Logo
