import {createSlice} from '@reduxjs/toolkit'
import config from './../../../config'
const lang = process.env.REACT_APP_I18N_LOCALE || config.languageFallback || 'sk'

const mapSlice = createSlice({
    name: 'users',
    initialState: {
        bounds: {},
        defaultCenter: {
            lat: Number(process.env[`REACT_APP_${lang.toUpperCase()}_LAT`]),
            lng: Number(process.env[`REACT_APP_${lang.toUpperCase()}_LNG`]),
        },
        localBounds: [],
        coordinates: {lat: 0, lng: 0},
        currentLocation: null,
        mapData: [],
        zoomLevel: 14,
        trafficLayer: [],
        hasChanges: false,
        toggleLocationsList: false,
        childClicked: false,
        selectedPlace: null,
        drawerOpen: false,
        toggleList: true,
        isPlaceConfirmDialogOpen: false
    },
    reducers: {
        setFieldValueOfMap: (state, action) => {
            // @ts-ignore
            state[action.payload.field] = action.payload.value
        }
    }
})

export const {setFieldValueOfMap} = mapSlice.actions
export default mapSlice.reducer