import {useDispatch, useSelector} from 'react-redux'
import {setFieldValueOfBasic} from '../store/features/Basic'
import {RootState} from './../store'

const useAppVersion = () => {
    const dispatch = useDispatch()
    const appVersion = useSelector((state: RootState) => state.basic.appVersion)
    const setAppVersion = (value: number) => {
        dispatch(setFieldValueOfBasic({
            field: 'appVersion',
            value
        }))
    }

    return {
        appVersion,
        setAppVersion
    }
}

export default useAppVersion