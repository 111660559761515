import useStyles from './styles'
import Slider from 'react-slick'
import {useTranslation} from 'react-i18next'
import React, {useEffect, useState} from 'react'
import {
    Alert,
    Avatar,
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    List,
    ListItem, ListItemButton,
    Snackbar,
    Typography
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import useMediaQuery from '@mui/material/useMediaQuery'
import ListItemAvatar from '@mui/material/ListItemAvatar'
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction'
import useToggleCurrentStates from '../../../../hooks/useToggleCurrentState'
import {getBearerToken, getClientZoneUrl, getConnectorName, getParamValue, getTimeIndication} from '../../../../utils'

import config from '../../../../config'
import {stateCommander} from '../../../../utils'
import useCurrentStates from '../../../../hooks/useCurrentStates'
import useCommandState from '../../../../hooks/useCommandState'
import { fetchCommandState, fetchCurrentStates } from '../../../../api'
import usePendingChargingState from '../../../../hooks/pendingChargingState'
import useLastUsedConnector from '../../../../hooks/useLastUsedConnector'
import useToggleMetricsDialog from '../../../../hooks/useToggleMetricsDialog'

import Cookies from 'universal-cookie'
const cookies = new Cookies()

const CurrentState = () => {
    const classes = useStyles()
    const {t} = useTranslation()
    const {currentStates} = useCurrentStates()
    const isDesktop = useMediaQuery('(min-width: 1024px)')
    const {toggleCurrentState, setToggleCurrentStates} = useToggleCurrentStates()
    const {setPendingChargingState} = usePendingChargingState()
    const {lastUsedConnector, setLastUsedConnector} = useLastUsedConnector()
    const [snackbar, setSnackbar] = useState({
        show: false,
        severity: '',
        message: ''
    })

    // --------- Selected state ---------
    const [selectedCurrentState, setSelectedCurrentState] = useState(0)

    // --------- Metric dialog ---------
    const {toggleMetricsDialog, setToggleMetricsDialog} = useToggleMetricsDialog()

    // --------- COMMAND STATE ---------
    const {commandState, setCommandState} = useCommandState()
    const fetchCommandStateData = async() => {
        await fetchCommandState().then((response) => {
            setCommandState(response)
        })
    }

    const gotoSummary = () => {
        // If there was an error or it was cancelled
        const gwStatus = cookies.get('gw_charging_status') || null
        if(gwStatus && (gwStatus === 'cancelled' || gwStatus === 'error')) {
            setLastUsedConnector(null)
            cookies.remove('gw_charging_status')
            return
        }

        if(lastUsedConnector) {
            const connector = lastUsedConnector
            setLastUsedConnector(null)
            const redirectLink = `${getClientZoneUrl(window.location.origin)}/charging?connectorId=${connector}`
            setTimeout(() => {
                window.open(redirectLink, '_top')
            }, 1500)
        }
    }
    const [commandStateCount, setCommandStateCount] = useState(0)
    useEffect(() => {
        setTimeout(() => {
            // If command state -> StartCharging / finished
            if(getBearerToken()) {
                // TODO - Andrej - check previous code if this doesn't work
                // now we dont care about stateCommand
                fetchCommandStateData().finally(() => {
                    setCommandStateCount(commandStateCount + 1)
                })
            }
        }, config.commandStateFetchTimeout)
    }, [commandStateCount])

    useEffect(() => {
        if(commandState && stateCommander(commandState)) {
            switch (stateCommander(commandState)) {
            case 'initializing':
                setPendingChargingState(true)
                break
            case 'getSummary':
                console.log('-- GO TO SUMMARY --')
                gotoSummary()
                break
            case 'error':
            default:
                break
            }
        }
    }, [commandState])

    // --------- CURRENT STATE ---------
    const {setCurrentStates} = useCurrentStates()
    const fetchCurrentStatesData = async() => {
        await fetchCurrentStates().then((response) => {
            if(response.length === 0) {
                gotoSummary()
                return
            }
            setCurrentStates(response)
            return response
        })
    }
    // current current state timeout
    // Load current state
    const [count, setCount] = useState(0)
    useEffect(() => {
        setTimeout(() => {
            // If command state -> StartCharging / finished
            if(getBearerToken()) {
                setPendingChargingState(false)
                fetchCurrentStatesData().finally(() => {
                    setCount(count + 1)
                })
            }
        }, config.currentStateFetchTimeout)
    }, [count])
    // --------- CURRENT STATE ---------

    useEffect(() => {
        if(getBearerToken()) {
            fetchCommandStateData()
            fetchCurrentStatesData()
        }
    }, [])

    const getPrice = (price) => {
        return `${price.priceKwh}${price.currency}/kWh + ${price.priceMinute}${price.currency}/min (${t('locationMap.after')} ${price.postponeMinutes} min)`
    }

    const stopCharging = (connector) => {
        const redirectLink = getParamValue('stopChargingRedirectLink') || `${getClientZoneUrl(window.location.origin)}/charging?connectorId=${connector}&action=stopCharging`
        window.open(redirectLink, '_top')
    }

    return (
        <>
            <Snackbar open={snackbar.show}
                      autoHideDuration={15000}
                      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                      onClose={() => {
                          setSnackbar({...snackbar, show: false})
                      }}
            >
                <Alert onClose={() => {
                            setSnackbar({...snackbar, show: false})
                        }}
                       severity={snackbar.severity}
                       sx={{ width: '100%' }}
                >
                    {snackbar.message}
                </Alert>
            </Snackbar>
            <Dialog open={toggleCurrentState}
                    fullScreen={true}
                    className={isDesktop ? classes.currentStateWrapperDesktop : classes.currentStateWrapper}
                    onClose={() => {
                        setToggleCurrentStates(false)
                    }}
                    key={`currentStateDialog`}
            >
                <DialogTitle className={`${isDesktop ? classes.dialogTitleDesktop : ''} ${classes.dialogTitle} ${currentStates[selectedCurrentState]?.no_communication ? classes.offlineBackground : ''}`}>
                    <Grid container>
                        <Grid item xs={12}>
                            <IconButton
                                edge="start"
                                color="inherit"
                                onClick={() => {
                                    setToggleCurrentStates(false)
                                }}
                                aria-label={t('locationMap.close')}
                                className={classes.closeButton}
                                sx={{
                                    position: 'absolute',
                                    right: 8,
                                    top: 8,
                                    color: (theme) => theme.palette.grey[500]
                                }}
                            >
                                <CloseIcon/>
                            </IconButton>
                            <Typography variant={'subtitle2'} className={classes.title}>
                                {
                                    currentStates[selectedCurrentState]?.no_communication ? t('locationMap.offline') : t('locationMap.charging')
                                }
                            </Typography>
                        </Grid>
                    </Grid>
                </DialogTitle>
                <DialogContent className={classes.dialogContent}>
                    {
                        currentStates[selectedCurrentState]?.measurements && (
                            <>
                                <Grid container>
                                    <Grid item xs={12}>
                                        <Slider arrows={false}
                                                slidesToShow={1}
                                                slidesToScroll={1}
                                                swipeToSlide={true}
                                                focusOnSelect={true}
                                                centerMode={true}
                                                dots={true}
                                                infinite={true}
                                                centerPadding="60px"
                                                className={`${classes.slider} ${ currentStates[selectedCurrentState]?.no_communication ? classes.noCommunication : ''}`}
                                        >
                                            {
                                                currentStates[selectedCurrentState]?.measurements.state_of_charge && (
                                                    <div>
                                                        <ListItem key={0} className={classes.listItem}>
                                                            <Avatar className={classes.avatar}>
                                                                <div className={classes.avatarContent}>
                                                                    <Typography variant={'caption'}
                                                                                className={classes.stateOfChargeTitle}
                                                                    >
                                                                        {t('locationMap.batteryState')}
                                                                    </Typography>
                                                                    <br/>
                                                                    <Typography variant={'title'}
                                                                                className={classes.stateOfChargePercentageValue}
                                                                    >
                                                                        {currentStates[selectedCurrentState]?.measurements.state_of_charge}
                                                                    </Typography>
                                                                    <span className={classes.stateOfChargePercentageSign}>%</span>
                                                                </div>
                                                            </Avatar>
                                                        </ListItem>
                                                    </div>
                                                )
                                            }
                                            {
                                                currentStates[selectedCurrentState]?.price && (
                                                    <div>
                                                        <ListItem key={0} className={classes.listItem}>
                                                            <Avatar className={classes.avatar}>
                                                                <div className={classes.avatarContent}>
                                                                    <Typography variant={'caption'}
                                                                                className={classes.stateOfChargeTitle}
                                                                    >
                                                                        {t('locationMap.chargingTime')}
                                                                    </Typography>
                                                                    <br/>
                                                                    <Typography variant={'title'}
                                                                                className={classes.stateOfChargePercentageValue}
                                                                    >
                                                                        {getTimeIndication(currentStates[selectedCurrentState]?.session)}
                                                                    </Typography>
                                                                </div>
                                                            </Avatar>
                                                        </ListItem>
                                                    </div>
                                                )
                                            }
                                            {
                                                currentStates[selectedCurrentState]?.measurements.active_energy_consumed && (
                                                    <div>
                                                        <ListItem key={0} className={classes.listItem}>
                                                            <Avatar className={classes.avatar}>
                                                                <div className={classes.avatarContent}>
                                                                    <Typography variant={'caption'}
                                                                                className={classes.stateOfChargeTitle}
                                                                    >
                                                                        {t('locationMap.consumedEnergy')}
                                                                    </Typography>
                                                                    <br/>
                                                                    <Typography variant={'title'}
                                                                                className={classes.stateOfChargePercentageValue}
                                                                    >
                                                                        {currentStates[selectedCurrentState]?.measurements.active_energy_consumed}
                                                                    </Typography>
                                                                    <span className={classes.stateOfChargePercentageSign}>
                                                                        {t('locationMap.kWh')}
                                                                    </span>
                                                                </div>
                                                            </Avatar>
                                                        </ListItem>
                                                    </div>
                                                )
                                            }
                                            {
                                                currentStates[selectedCurrentState]?.measurements.power && (
                                                    <div>
                                                        <ListItem key={0} className={classes.listItem}>
                                                            <Avatar className={`${classes.avatar} ${classes.avatarButton}`}
                                                                    onClick={() => {
                                                                        setToggleMetricsDialog(true)
                                                                    }}
                                                            >
                                                                <div className={classes.avatarContent}>
                                                                    <Typography variant={'caption'}
                                                                                className={classes.stateOfChargeTitle}
                                                                    >
                                                                        {t('locationMap.chargingPower')}
                                                                    </Typography>
                                                                    <br/>
                                                                    <Typography variant={'title'}
                                                                                className={classes.stateOfChargePercentageValue}
                                                                    >
                                                                        {currentStates[selectedCurrentState]?.measurements.power}
                                                                    </Typography>
                                                                    <span className={classes.stateOfChargePercentageSign}>kW</span>
                                                                    <br/>
                                                                    <Typography variant={'caption'} className={classes.moreInfo}>
                                                                        { t('locationMap.moreInfo') }
                                                                    </Typography>
                                                                </div>
                                                            </Avatar>
                                                        </ListItem>
                                                    </div>
                                                )
                                            }
                                        </Slider>
                                    </Grid>
                                </Grid>
                            </>
                        )
                    }
                    <Grid container className={classes.grid}>
                        <Grid item xs={12}>
                            <List className={classes.listWrapper} dense>
                                {
                                    !currentStates[selectedCurrentState]?.hasOwnProperty('command') && currentStates.map((item, index) => (
                                        <ListItem key={`${item['location_id']}`}
                                                  className={classes.listItemConnector}
                                                  sx={{paddingLeft: 0, padding: '0', margin: '10px 0'}}
                                                  onClick={() => {setSelectedCurrentState(index)}}

                                        >
                                            <ListItemButton selected={selectedCurrentState === index}>
                                                <ListItemAvatar>
                                                    <Avatar alt={item.name}
                                                            src={item.location.default_photo}
                                                            variant="rounded"
                                                            sx={{width: 60, height: 60, marginRight: '15px'}}
                                                            className={classes.avatarConnector}
                                                    />
                                                </ListItemAvatar>

                                                <div className={classes.locationInfoWrapper}>
                                                    <Typography variant={'caption'} className={classes.titleWrapper}>
                                                        <span>{item.location['name']}</span>
                                                    </Typography>
                                                    <br/>
                                                    <Typography variant={'caption'} className={classes.ownerType}>
                                                        {item.connector['code']}
                                                    </Typography>
                                                    <br/>
                                                    <Typography variant={'caption'} className={classes.price}>
                                                        {getPrice(item.price)}
                                                    </Typography>
                                                </div>

                                                <ListItemSecondaryAction className={classes.ListItemSecondary}
                                                                         sx={{right: 0, lineHeight: 1}}
                                                >
                                                    <div className={classes.locationAdditionalInfoWrapper}>
                                                        <Typography variant={'subtitle1'} className={classes.maxPower}>
                                                            {item.connector['max_power']}{t('locationMap.kW')}
                                                        </Typography>
                                                        <Typography variant={'subtitle1'} className={classes.maxPower}>
                                                            {getConnectorName(item.connector.type, t)}
                                                        </Typography>
                                                    </div>
                                                </ListItemSecondaryAction>
                                            </ListItemButton>

                                        </ListItem>
                                    ))
                                }
                            </List>
                        </Grid>
                        <Grid item xs={12} className={classes.bottomPart}>
                            <div className={classes.buttonsWrapper}>
                                <Button variant="outlined"
                                        color="error"
                                        className={classes.endChargingSession}
                                        disabled={currentStates[selectedCurrentState]?.no_communication}
                                        onClick={() => {
                                            stopCharging(currentStates[selectedCurrentState].connector['connector_id'])
                                        }}
                                >
                                    {t('locationMap.endChargingSession')}
                                </Button>
                                <Button variant="outlined"
                                        className={classes.bigCloseButton}
                                        onClick={() => {
                                            setToggleCurrentStates(false)
                                        }}
                                >
                                    {t('locationMap.close')}
                                </Button>
                            </div>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        </>
    )
}

export default CurrentState