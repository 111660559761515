import {RootState} from './../store'
import {useDispatch, useSelector} from 'react-redux'
import {setFieldValueOfMap} from "../store/features/Map";

const useChildClicked = () => {
    const dispatch = useDispatch()
    const childClicked = useSelector((state: RootState) => state.map.childClicked)
    const setChildClicked = (value: number | null) => {
        dispatch(setFieldValueOfMap({
            field: 'childClicked',
            value
        }))
    }

    return {
        childClicked,
        setChildClicked
    }
}

export default useChildClicked