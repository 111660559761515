import {configureStore} from '@reduxjs/toolkit'
import basicReducer from './features/Basic'
import mapReducer from './features/Map'
import filterReducer from './features/Filter'
import locationReducer from './features/Location'

const store = configureStore({
    reducer: {
        basic: basicReducer,
        map: mapReducer,
        location: locationReducer,
        filter: filterReducer
    }
})

export type RootState = ReturnType<typeof store.getState>
export default store
