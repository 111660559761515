import {RootState} from './../store'
import {useDispatch, useSelector} from 'react-redux'
import {setFieldValueOfMap} from "../store/features/Map";

const useSelectedPlace = () => {
    const dispatch = useDispatch()
    const selectedPlace = useSelector((state: RootState) => state.map.selectedPlace)
    const setSelectedPlace = (value: number | null) => {
        dispatch(setFieldValueOfMap({
            field: 'selectedPlace',
            value
        }))
    }

    return {
        selectedPlace,
        setSelectedPlace
    }
}

export default useSelectedPlace