import {makeStyles} from '@mui/styles';
import {Theme} from '@mui/material'

export default makeStyles((theme: Theme) => ({
    bottomPart: {
        display: 'flex',
        justifyContent: 'flex-end',
        '&.MuiGrid-root': {
            flexDirection: 'column'
        }
    },
    bigCloseButton: {
        width: '100%',
        '&.MuiButton-root': {
            height: '3rem',
            marginTop: '1.5rem',
            borderRadius: 25,
            borderColor: `${theme.palette.grey["600"]} !important`,
            color: `${theme.palette.grey["600"]} !important`,
            '&:hover': {
                cursor: 'pointer',
                borderColor: `${theme.palette.grey["900"]} !important`,
                color: `${theme.palette.grey["900"]} !important`
            }
        }
    },
    zIndexAuto: {
        zIndex: 'auto !important'
    },
    metricDialog: {
        '& .MuiPaper-root': {
            borderTopLeftRadius: '15px',
            borderTopRightRadius: '15px'
        }
    },
    metricDialogClose: {
        textAlign: 'right'
    },
    metricDialogText: {
        '&.MuiTypography-root': {
            whiteSpace: 'pre-line',
            color: theme.palette.common.black
        }
    },
    metricDialogFooter: {
        width: '100%',
        margin: 'auto'
    },
    metricDialogHeaderContainer: {
        display: 'flex',
        alignItems: 'center',

    },
    metricDialogTitleWrapper: {
        '&.MuiGrid-root': {
            paddingLeft: '16.666667%',
            textAlign: 'center'
        }
    },
    moreInfo: {
        textTransform: 'uppercase'
    }
}))