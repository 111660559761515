import {RootState} from './../store'
import {useDispatch, useSelector} from 'react-redux'
import {setFieldValueOfMap} from '../store/features/Map'

const useCurrentLocation = () => {
    const dispatch = useDispatch()
    const currentLocation = useSelector((state:RootState) => state.map.currentLocation)
    const setCurrentLocation = (value) => {
        dispatch(setFieldValueOfMap({
            field: 'currentLocation',
            value
        }))
    }

    return {
        currentLocation,
        setCurrentLocation
    }
}

export default useCurrentLocation