import {useDispatch, useSelector} from 'react-redux'
import {setFieldValueOfLocation} from '../store/features/Location'
import {RootState} from './../store'

const useToggleAccessInstructions = () => {
    const dispatch = useDispatch()
    const toggleAccessInstructions = useSelector((state: RootState) => state.location.toggleAccessInstructions)
    const setToggleAccessInstructions = (value) => {
        dispatch(setFieldValueOfLocation({
            field: 'toggleAccessInstructions',
            value
        }))
    }
    return {
        toggleAccessInstructions,
        setToggleAccessInstructions
    }
}

export default useToggleAccessInstructions