import {makeStyles} from '@mui/styles'
import {Theme} from '@mui/material'

// @ts-ignore
export default makeStyles((theme: Theme) => ({
    topSection: {},
    topSectionTitle: {
        clear: 'both'
    },
    middleSection: {
        height: '45vh',
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex'
    },
    bottomSection: {
        justifyContent: 'center',
        textAlign: 'center',
        marginBottom: '10px',
        '& .MuiButton-root': {
            width: '100%',
            padding: '10px 0',
            color: theme.palette.common.white,
            borderColor: theme.palette.common.white,
            borderRadius: '25px',
            textTransform: 'capitalize',
            marginTop: '15px'
        }
    },
    wrapper: {
        '& .slick-track': {
            display: 'flex',
            alignItems: 'center'
        },
        '& .MuiPaper-root': {
            backgroundColor: theme.palette.common.black,
            color: theme.palette.common.white,
            justifyContent: 'end'
        },
        '& .MuiDialogContentText-root': {
            color: theme.palette.common.white,
            zIndex: 1,
            textAlign: 'center'
        },
        '& .MuiDialogTitle-root': {
            marginTop: '20px',
            color: theme.palette.common.white,
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center'
        },
        '& .MuiTypography-root': {
            zIndex: 1
        },
        '& .MuiDialogContent-root': {
            paddingTop: '5px !important',
            overflow: 'auto',
            zIndex: 1
        }
    },
    closeButton: {
        '&.MuiButtonBase-root': {
            float: 'right',
            top: '-30px',
            right: '-20px',
            zIndex: 9990
        },
        '& .MuiSvgIcon-root': {
            fontSize: '2.5rem'
        }
    },
    selectLabel: {
        background: '#231F20',
        padding: '0 10px',
        '&.MuiFormLabel-root': {
            color: theme.palette.grey[400]
        }
    },
    selectCamera: {
        width: '100%',
        marginBottom: '15px',
        textAlign: 'left',
        color: theme.palette.common.white,
        '& .MuiInputBase-input': {
            color: theme.palette.common.white,
            border: `1px solid ${theme.palette.common.white}`
        },
        '& .MuiSvgIcon-root': {
            color: theme.palette.common.white
        }
    },
    QrReaderMobile: {
        '& div': {
          paddingTop: '0 !important',
          height: '325px'
        },
        '& #video': {
        }
    },
    QrReaderDesktop: {
        '& div': {
            paddingTop: '0 !important',
            height: '375px'
        },
        '& #video': {
            MaxHeight: '320px'
        }
    },
    QrBoundaries: {
        '& img': {
            height: '30vh',
            background: 'rgb(255 255 255 / 20%)',
            borderRadius: '7%',
            transform: 'scale(1.25)'
        }
    },
    wordBreakAll: {
        wordBreak: 'break-all'
    },
    dialogWrapper: {}
}))