import {makeStyles} from '@mui/styles';
import {Theme} from '@mui/material'
import { grey } from '@mui/material/colors';

// @ts-ignore
export default makeStyles((theme: Theme) => ({
    container: {
        width: '100%'
    },
    greenColor: {
        color: theme.palette.primary.main
    },
    orangeColor: {
        color: theme.palette.warning.main
    },
    redColor: {
        color: theme.palette.error.main
    },
    greyColor: {
        color: theme.palette.grey[500]
    },
    blueColor: {
        color: theme.palette.secondary.main
    },
    title: {
        height: '48px',
        background: grey["A100"],
        color: grey[700],
        display: 'flex',
        alignItems: 'center',
        paddingLeft: '1rem',
        textTransform: 'uppercase'
    },
    deviceWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        height: '48px',
        background: grey["A100"],
        color: grey[700],
        alignItems: 'center',
        padding: '0 1rem',
        textTransform: 'uppercase'
    },
    connectorsWrapper: {
        '& .MuiListItem-root': {
            padding: '0 10px 0 0',
            '&:hover': {
                cursor: 'pointer',
                background: grey["100"]
            }
        }
    },
    connectorListItem: {
        paddingLeft: 0,
        '&.MuiButtonBase-root': {
            '&:hover': {
                background: 'none'
            }
        }
    },
    connectorLeftSideWrapper: {
        '&.MuiListItem-root': {
            paddingLeft: 0,
            paddingRight: 0,
        },
        '& .MuiListItemText-secondary': {
            color: grey[700]
        }
    },
    connectorPrice: {
        '& .MuiListItemText-primary': {
            fontWeight: theme.typography.fontWeightBold,
            marginBottom: '5px',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            width: '125px',
        },
        '& .MuiListItemText-secondary': {
            fontSize: '0.875rem'
        }
    },
    connectorIcon: {
        '&.MuiListItemText-root': {
            textAlign: 'center',
            marginRight: '25px',
            display: 'flex',
            flexDirection: 'column',
            flexWrap: 'wrap',
            width: '60px',
            minWidth: '60px',
            maxWidth: '60px',
            '& .MuiListItemText-primary': {
                width: '50px',
                display: 'flex',
                flexWrap: 'wrap',
                flexDirection: 'column',
                alignContent: 'center'
            },
            '& .MuiListItemText-secondary': {
                fontSize: '0.675rem',
                width: '50px',
                display: 'flex',
                flexWrap: 'wrap',
                flexDirection: 'column',
                alignContent: 'center'
            }
        },
        '& img': {
            width: '30px'
        }
    },
    chargerTitle: {
        display: 'flex',
        justifyContent: 'start',

    },
    parkingLots: {
        color: grey[400],
        display: 'flex',
        justifyContent: 'end',
        alignItems: 'center',
        '& .MuiTypography-root ': {
            fontWeight: theme.typography.fontWeightBold,
            fontSize: '1.2rem'
        }
    },
    iconForward: {
        '& .MuiListItemText-root': {
            display: 'inline-table',
            textAlign: 'right'
        },
        '& .MuiListItemSecondaryAction-root': {
            right: '-25px',
            overflow: 'hidden'
        },
        '& .MuiListItemText': {
            textAlign: 'right'
        },
        '& .MuiListItemText-primary': {
            marginBottom: '5px',
            fontWeight: theme.typography.fontWeightBold,
        },
        '& .MuiListItemText-secondary': {
            color: grey[700]
        }
    }
}));