import {makeStyles} from '@mui/styles';
import {Theme} from '@mui/material'

// @ts-ignore
export default makeStyles((theme: Theme) => ({
    galleryWrapper: {
        '& .slick-track': {
            display: 'flex',
            alignItems: 'center'
        },
        '& .MuiPaper-root': {
            backgroundColor: theme.palette.common.black,
            color: theme.palette.common.white,
            justifyContent: 'end'
        },
        '& .MuiImageListItem-img': {
            objectFit: 'contain !important',
            maxHeight: '80vh'
        }
    },
    '.slick-slider': {
        backgroundColor: theme.palette.common.black
    },
    '.slick-dots': {
        '& li button::before': {
            color: theme.palette.common.white
        }
    },
    sliderWrapperDesktop: {
        height: '100%',
        marginTop: '5vh'
    },
    sliderWrapper: {
        height: '100%',
        marginTop: '25vh'
    },
    closeButton: {
        '&.MuiButtonBase-root': {
            position: 'absolute',
            top: '10px',
            right: '5px',
            zIndex: 9999,
            '&:hover': {
                background: theme.palette.primary.contrastText
            }
        },
        '& .MuiSvgIcon-root': {
            fontSize: '2.5rem'
        }
    },
    dotStyleWrapper: {

    },
    dotStyle: {
        width: '64px'
    },
    sliderTopMobile: {
        '& .slick-dots': {
            position: 'relative',
            '& ul': {
                margin: 0,
                padding: 0,
                whiteSpace: 'nowrap',
                overflow: 'auto',
                '& li': {
                    width: '64px',
                    height: '64px'
                }
            }
        },
        '& .MuiImageListItemBar-title': {
            textAlign: 'center'
        }
    },
    sliderTopDesktop: {
        height: '65vh',
        '& img': {
            maxHeight: '600px',
            width: 'auto !important',
            margin: 'auto'
        },
        '& .MuiImageListItemBar-title': {
            textAlign: 'center'
        },
        '& .slick-dots': {
            bottom: '-40%',
            '& ul': {
                margin: 0,
                padding: 0,
                '& li': {
                    width: '64px',
                    height: '64px'
                }
            }
        }
    }
}));