import { makeStyles } from '@mui/styles';
import {Theme} from '@mui/material'

export default makeStyles((theme:Theme) => ({
    container: {
        padding: '25px',
        [theme.breakpoints.down("md")]: {
            '& .MuiPaper-root': {
                borderRadius: '16px 16px 0 0',
            },
            '&.MuiDrawer-root > .MuiPaper-root': {
                height: `97%`,
                overflow: 'visible',
            }
        }
    },
    closeButton: {
        paddingTop: '7px',
        paddingRight: '1rem',
        textAlign: 'right',
        '& .MuiSvgIcon-root': {
            color: theme.palette.grey[300]
        }
    },
    topSection: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      '& .MuiTypography-root': {
          fontWeight: theme.typography.fontWeightBold,
          color: theme.palette.text.primary
      }
    },
    title: {
        textAlign: 'center',
        paddingBottom: '1rem',
        '& .MuiTypography-root': {
            color: theme.palette.common.black,
            fontWeight: theme.typography.fontWeightBold,
        }
    },
    subTitle: {
        '&.MuiTypography-root': {
            fontWeight: theme.typography.fontWeightBold
        },
        '& .MuiTypography-root': {
            fontWeight: theme.typography.fontWeightBold
        }
    },
    locationDetailsInfo: {
        lineHeight: 2,
        color: theme.palette.grey[700],
        overflow: 'auto'
    },
    locationDetailsInfoLeft: {
        width: '60%',
        textAlign: 'left'
    },
    locationDetailsInfoRight: {
        textAlign: 'right',
        '& .MuiTypography-root': {
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis'
        }
    }
}))