import React, {useState} from 'react'
import useStyles from './styles'

import RedDot from './../../../../assets/pins/redDot.svg'
import PinAvailable from './../../../../assets/pins/available.svg'
import PinOffline from './../../../../assets/pins/offline.svg'
import PinCharging from './../../../../assets/pins/charging.svg'
import PinChargingOffline from './../../../../assets/pins/chargingOffline.svg'

import {getCurrentStateText} from "../../../../utils";
import {Box} from '@mui/material'
import {useTranslation} from "react-i18next"
function Pin({cluster, setActiveLocation, currentState}) {
    const classes = useStyles()
    const pin = cluster.properties
    const { t } = useTranslation()
    const [showPin, setShowPin] = useState(false);

    const getCurrentStatePin = () => {
        if(currentState['no_communication']) {
            return PinChargingOffline
        }
        return PinCharging
    }

    const getPinType = (type) => {
        switch (type) {
            case 'available':
            case 'preparing':
            case 'finishing':
                return PinAvailable
            case 'maintenance':
                return RedDot
            case 'occupied':
            case 'offline':
            case 'closed':
                return PinOffline
            case 'charging':
                if(!currentState) {
                    return PinChargingOffline
                }
                return PinCharging
            default:
                return null
        }
    }

    const isGreenWay = () => {
        return pin?.owner_type?.toLowerCase() === 'greenway'
    }

    const getOwner = (type) => {
        switch (type) {
            case 'Greenway':
                return null
            case 'Partner':
                return 'P'
            case 'Roaming':
                return 'R'
            default:
                return '?'
        }
    }

    const showText = (availability) => {
        switch (availability) {
            case 'maintenance':
                return false
            default:
                return true
        }
    }

    const showInnerText = (availability) => {
        switch (availability) {
            case 'maintenance':
            case 'charging':
                return false
            default:
                return true
        }
    }

    return (
        getPinType(pin['availability']) && (
            <div className={classes.markerContainer}>
                {
                    !currentState && showPin && showInnerText(pin['availability']) && (
                        <div className={classes.pinNumber}>
                            <strong>{pin.max_power ? pin.max_power : '? '}</strong>
                            <small>{t('locationMap.kW')}</small>
                        </div>
                    )
                }
                {
                    currentState ? (
                        showPin && (
                            <Box className={`${classes.rectangleCurrentState} ${classes.borderRadiusAround} ${currentState.measurements['state_of_charge'] || currentState.session['server_time'] ? classes.extraPositionCurrentState : '' }`}
                                 boxShadow={3}
                            >
                                <span className={`${classes.rectangleTextCurrentState}`}>
                                    {getCurrentStateText(currentState, t)}
                                </span>
                            </Box>
                        )
                    ) : (
                        showText(pin['availability']) && showPin && (
                            <Box className={`${classes.rectangle} ${!isGreenWay() ? classes.extraPositionAdjustment : ''}`}
                                 boxShadow={3}
                            >
                            <span className={`${classes.rectangleText} ${isGreenWay() ? classes.borderRadiusAround : classes.borderRadiusLeft}`}>
                                {pin.available}
                            </span>
                            {
                                !isGreenWay() && (
                                    <span className={`${classes.rectangleTextBlack} ${classes.borderRadiusRightBlack}`}>
                                        {getOwner(pin['owner_type'])}
                                    </span>
                                )
                            }
                            </Box>
                        )
                    )
                }

                <img src={PinOffline} className={classes.hide} alt=""/>
                <img src={PinAvailable} className={classes.hide} alt=""/>
                <img src={PinCharging} className={classes.hide} alt=""/>
                <img src={PinChargingOffline} className={classes.hide} alt=""/>
                <img src={RedDot} className={classes.hide} alt=""/>

                <img src={currentState ? getCurrentStatePin() : getPinType(pin['availability'])}
                     className={classes.pointer}
                     alt={pin.name}
                     height={50}
                     onClick={() => setActiveLocation(cluster)}
                     onLoad={() => {
                         setShowPin(true)
                     }}
                />
        </div>
        )
    )
}

export default Pin