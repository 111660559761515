import {useDispatch, useSelector} from 'react-redux'
import {setFieldValueOfLocation} from '../store/features/Location'
import {RootState} from './../store'

const useToggleSeachLocation = () => {
    const dispatch = useDispatch()
    const toggleSearchLocation = useSelector((state: RootState) => state.location.toggleSearchLocation)
    const setToggleSearchLocation = (value) => {
        dispatch(setFieldValueOfLocation({
            field: 'toggleSearchLocation',
            value
        }))
    }

    return {
        toggleSearchLocation,
        setToggleSearchLocation
    }
}

export default useToggleSeachLocation