import React, {useEffect, useRef, useState} from 'react'
import GoogleMapReact from 'google-map-react'
import useSuperCluster from 'use-supercluster'
import useStyles from './styles'

import Pin from './components/Pin'
import Logo from './components/Logo'
import Cluster from './components/Cluster'

import {setFieldValueOfMap} from '../../store/features/Map'
import {useDispatch, useSelector} from 'react-redux'
import {CircularProgress} from '@mui/material'
import MapTypeController from './components/MapTypeController'

import useCoordinates from '../../hooks/useCoordinates'
import useCurrentLocation from '../../hooks/useCurrentLocation'
import useMediaQuery from '@mui/material/useMediaQuery'
import useHasChanges from '../../hooks/useHasChanges'
import IconQr from '../../assets/icons/scanQrWhite.svg'
import ScanQR from '../ScanQR'
import Filter from './components/Filter'
import {useTranslation} from 'react-i18next'
import useCurrentStates from '../../hooks/useCurrentStates'
import CurrentState from './components/CurrentState'
import Recenter from './components/Recenter'
import UserMenu from './components/UserMenu'
import {getParamValue, isMobilePhone} from '../../utils'
import useChildClicked from './../../hooks/useChildClicked'
import useDefaultCenter from '../../hooks/useDefaultCenter'
import useSelectedPlace from '../../hooks/useSelectedPlace'
import PinPlace from './components/PinPlace'
import CurrentLocation from './components/CurrentLocation'
import usePendingChargingState from '../../hooks/pendingChargingState'
import {useDebounce} from 'use-debounce'
import {getZoomLevel} from '../../utils'
import DialogMetrics from '../LocationDetail/components/DialogMetrics'
import Cookies from 'universal-cookie'

const Map = ({showLocationsList}) => {
    const mapRef = useRef()
    const classes = useStyles()
    const dispatch = useDispatch()
    const isDesktop = useMediaQuery('(min-width: 900px)')
    const { t } = useTranslation()
    const isLoading = useSelector((state) => state['basic'].isLoading)
    const isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1
    const isIFrame = Boolean(getParamValue('isIframe')) || false
    const {setChildClicked} = useChildClicked()
    const {defaultCenter} = useDefaultCenter()
    const {pendingChargingState} = usePendingChargingState()

    const cookies = new Cookies()

    // map API debounce
    const [value, setValue] = React.useState(null)
    const [debounceValue] = useDebounce(value, process.env.REACT_APP_MAP_DRAG_END_DEBOUNCE)
    useEffect(() => {
        setHasChanges(true)
    }, [debounceValue])

    const Marker = ({children}) => children
    const [points, setPoints] = useState([])
    const [enableControllers, setEnableControllers] = useState(false)

    // Basic - is loading
    const isMapLoading = useSelector((state) => state.basic.isMapLoading)

    // Map - map data
    const mapData = useSelector((state) => state.map.mapData)

    // Location - current states
    const {currentStates} = useCurrentStates()

    // Map - current location
    const {currentLocation, setCurrentLocation} = useCurrentLocation()

    // Map - coordinates
    const {coordinates, setCoordinates} = useCoordinates()

    // Map - has Changes
    const {setHasChanges} = useHasChanges()

    // Map - bounds
    const setBounds = (value) => {
        dispatch(setFieldValueOfMap({
            field: 'bounds',
            value
        }))
    }

    // Pin Place
    const {selectedPlace} = useSelectedPlace()

    // check if Pin is in currentStates
    const getCurrentState = (location_id) => {
        return currentStates?.find(el => el.location?.location_id === location_id) || null
    }

    // Map - local bounds
    const localBounds = useSelector((state) => state.map.localBounds)
    const setLocalBounds = (value) => {
        dispatch(setFieldValueOfMap({
            field: 'localBounds',
            value
        }))
    }

    // Map - traffic layer
    const trafficLayer = useSelector((state) => state.map.trafficLayer)

    // Map - zoomLevel
    const defaultZoomLevel = 14
    const zoomLevel = useSelector((state) => state.map.zoomLevel)
    const setZoomLevel = (value) => {
        dispatch(setFieldValueOfMap({
            field: 'zoomLevel',
            value
        }))
    }

    // if location_id is provided setActive location
    useEffect(() => {
        const location_id = getParamValue('locationId')
        // const location_id = cookies.get('gw_location_id') || null
        if(location_id) {
            setActiveLocation({
                properties: {
                    location_id
                }
            })
            setTimeout(() => {
                // let url = new URL(window.location.href)
                // url.searchParams.delete('locationId');

                // const hostName = window.location.hostname
                // const domain = hostName.substring(hostName.lastIndexOf('.', hostName.lastIndexOf('.') - 1) + 1)
                // const envExpire = Number(process.env.REACT_APP_COOKIE_LANG_EXPIRATION) || 400
                // const maxAge = 3600 * 24 * envExpire
                // cookies.remove('gw_location_id', {
                //     domain
                // })

                // window.history.pushState({}, null, url)
            }, 5000)
        }
    }, [])
    const setActiveLocation = (item) => {
        if (!item['isCluster']) {
            setZoomLevel(getZoomLevel(zoomLevel))
            setTimeout(() => {
                setChildClicked(null)
                setTimeout(() => {
                    window.history.pushState(null, '', `/?locationId=${item.properties.location_id}`);
                    setChildClicked(item.properties.location_id)
                }, 100)
            }, process.env.REACT_APP_MAP_ZOOM_DEBOUNCE)
        }
    }
    const getPoints = (clusterId) => {
        return clusterId ? supercluster.getLeaves(clusterId, Infinity) : null
    }

    const [geolocationEnabled, setGeolocationEnabled] = useState(null)
    useEffect(() => {
        // if we open page on mobile phone
        // if(isMobilePhone()) {
            // go to full screen and lock it
            // document.getElementById('root').requestFullscreen()
            // window.screen.orientation.lock('portrait')
        // }

        if(isFirefox && isIFrame) {
            const latitude = Number(getParamValue('lat')) || null
            const longitude = Number(getParamValue('lng')) || null
            if(latitude && longitude) {
                setCurrentLocation({lat: latitude, lng: longitude})
            }
            setGeolocationEnabled(false)
        } else {
            navigator.geolocation.getCurrentPosition(({coords: {latitude, longitude}}) => {
                setCurrentLocation({lat: latitude, lng: longitude})
            })
            setGeolocationEnabled(true)
        }
    }, [localBounds, zoomLevel])

    useEffect(() => {
        // can be also [] -> no results
        const data = mapData.map(item => ({
            type: 'Location',
            properties: {
                cluster: false,
                ...item
            },
            geometry: {
                type: 'Point',
                coordinates: [
                    Number(item.longitude),
                    Number(item.latitude)
                ]
            }
        }))
        setPoints(data)
    }, [mapData])

    const {clusters, supercluster} = useSuperCluster({
        points,
        bounds: localBounds,
        zoom: zoomLevel,
        options: {
            radius: 70,
            maxZoom: 16
        },
        generateId: true,
        log: true
    })

    const [debounceZoomValue] = useDebounce(zoomLevel, process.env.REACT_APP_MAP_ZOOM_DEBOUNCE)
    useEffect(() => {
        setHasChanges(true)
    }, [debounceZoomValue])

    return (
        <div className={`${isDesktop ? classes.mapContainerDesktop : classes.mapContainer} ${showLocationsList ? classes.showList : classes.hideList}`}>
            {
                (geolocationEnabled === null) && (
                    <div className={isDesktop ? classes.geolocationInfoDesktop : classes.geolocationInfo}>
                        <h4>
                            {t('locationMap.enableGeolocation')}
                        </h4>
                    </div>
                )
            }
            {

                isMapLoading ? (
                    <div className={classes.loading}>
                        <CircularProgress size="5rem"/>
                    </div>
                ) : (
                    <GoogleMapReact bootstrapURLKeys={{key: process.env.REACT_APP_API_KEY}}
                                    center={coordinates}
                                    defaultCenter={defaultCenter}
                                    defaultZoom={defaultZoomLevel}
                                    zoom={zoomLevel}
                                    margin={[50, 50, 50, 50]}
                                    onDragEnd={(event) => {
                                        setValue(event.rmiUrl)
                                    }}
                                    onZoomAnimationEnd={(event) => {
                                        setZoomLevel(event)
                                    }}
                                    onChange={(event) => {
                                        if (event.center?.lat && event.center?.lng) {
                                            setCoordinates({
                                                lat: event.center.lat,
                                                lng: event.center.lng
                                            })
                                            setBounds({
                                                ne: event.marginBounds.ne,
                                                sw: event.marginBounds.sw
                                            })
                                            setLocalBounds([
                                                event.bounds.nw?.lng,
                                                event.bounds.se?.lat,
                                                event.bounds.se?.lng,
                                                event.bounds.nw?.lat
                                            ])
                                        } else {
                                            setCoordinates(defaultCenter)
                                        }
                                    }}
                                    yesIWantToUseGoogleMapApiInternals
                                    onGoogleApiLoaded={({map}) => {
                                        // Andrej - DEV-6295 - re-enabled again...
                                        map.streetViewControl = true
                                        map.mapTypeControl = true
                                        mapRef.current = map
                                        setEnableControllers(true)
                                        setHasChanges(true)
                                    }}
                                    layerTypes={trafficLayer}
                                    style={{
                                        height: '95%'
                                    }}
                                    options={(maps) => ({
                                        gestureHandling: 'greedy',
                                        zoomControl: true,
                                        fullscreenControl: false,
                                        mapTypeControl: false,
                                        zoomControlOptions: {
                                            position: maps.ControlPosition.BOTTOM_LEFT
                                        },
                                        streetViewControlOptions: {
                                            position: maps.ControlPosition.LEFT_TOP
                                        },
                                        tilt: 0,
                                        rotateControl: false
                                    })}
                    >
                        {
                            points && clusters?.map((cluster, i) => {
                                const [longitude, latitude] = cluster.geometry.coordinates
                                const {cluster: isCluster, point_count: pointCount} = cluster.properties

                                if (isCluster) {
                                    const points = getPoints(cluster.id)
                                    return points && (
                                        <Marker key={i}
                                                lat={Number(latitude)}
                                                lng={Number(longitude)}
                                                title={cluster.properties.name}
                                        >
                                            <Cluster cluster={cluster}
                                                     supercluster={supercluster}
                                                     latitude={latitude}
                                                     longitude={longitude}
                                                     pointCount={pointCount}
                                                     mapRef={mapRef}
                                                     points={points}
                                            />
                                        </Marker>
                                    )
                                }

                                return (
                                    <div className={classes.marker}
                                         onClick={isLoading ? ()=>{} : () => setActiveLocation(cluster)}
                                         lat={Number(latitude)}
                                         lng={Number(longitude)}
                                         key={i}
                                    >
                                        <Marker key={i}
                                                title={cluster['properties'].name}
                                        >
                                            <Pin cluster={cluster}
                                                 mapRef={mapRef}
                                                 setActiveLocation={setActiveLocation}
                                                 currentState={getCurrentState(cluster['properties'].location_id)}
                                            />
                                        </Marker>
                                    </div>
                                )
                            })
                        }

                        {
                            selectedPlace && (
                                <div className={classes.marker}
                                     onClick={isLoading ? ()=>{} : () => {
                                         // TODO
                                     }}
                                     lat={Number(selectedPlace.latitude)}
                                     lng={Number(selectedPlace.longitude)}
                                     key="selected-place"
                                >
                                    <Marker key="selected-place"
                                            title={selectedPlace.name}
                                    >
                                        <PinPlace selectedPlace={selectedPlace}/>
                                    </Marker>
                                </div>
                            )
                        }
                        {
                            (currentLocation && coordinates && geolocationEnabled !== null) && (
                                <CurrentLocation currentLocation={currentLocation}
                                                 lat={Number(currentLocation?.lat)}
                                                 lng={Number(currentLocation?.lng)}
                                />
                            )
                        }
                    </GoogleMapReact>
                )}
            {
                enableControllers &&
                <>
                    <Logo/>
                    <UserMenu/>
                    <DialogMetrics/>
                    <MapTypeController mapRef={mapRef}/>
                    {
                        (currentLocation && coordinates && geolocationEnabled !== null) && (
                            <Recenter />
                        )
                    }
                    <Filter/>
                    {
                        currentStates?.length || pendingChargingState ? (
                            <div className={isDesktop ? classes.scanQRCurrentStates : classes.scanQRCurrentStatesMobile}>
                                <CurrentState />
                            </div>
                        ) : (
                            <div className={isDesktop ? classes.scanQRDesktop : classes.scanQR}>
                                <ScanQR IconQr={IconQr}/>
                            </div>
                        )
                    }
                </>
            }
        </div>
    )
}

export default Map