import {RootState} from './../store'
import {useDispatch, useSelector} from 'react-redux'
import {setFieldValueOfMap} from "../store/features/Map";

const useShowHideSearchResults = () => {
    const dispatch = useDispatch()
    const toggleList = useSelector((state: RootState) => state.map.toggleList)
    const setToggleList = (value: boolean | null) => {
        dispatch(setFieldValueOfMap({
            field: 'toggleList',
            value
        }))
    }

    return {
        toggleList,
        setToggleList
    }
}

export default useShowHideSearchResults