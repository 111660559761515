import React from 'react'
import useStyles from './styles'
import {useTranslation} from 'react-i18next'
import CloseIcon from '@mui/icons-material/Close'
import {Transition} from 'react-transition-group'
import useToggleMetricsDialog from '../../../../hooks/useToggleMetricsDialog'
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
    IconButton, Slide,
    Typography
} from '@mui/material'
import {TransitionProps} from '@mui/material/transitions'
import useMediaQuery from '@mui/material/useMediaQuery'
const DialogMetrics = () => {
    const classes = useStyles()
    const {t} = useTranslation()
    const {toggleMetricsDialog, setToggleMetricsDialog} = useToggleMetricsDialog()

    const Transition = React.forwardRef(function Transition(
        props: TransitionProps & {
            children: React.ReactElement<any, any>;
        },
        ref: React.Ref<unknown>,
    ) {
        return <Slide direction="up" ref={ref} {...props} />;
    });

    return (
        <Dialog open={toggleMetricsDialog}
                fullScreen={!useMediaQuery('(min-width: 900px)')}
                TransitionComponent={Transition}
                key={`toggleMetricsDialog`}
                className={`${toggleMetricsDialog ? '' : classes.zIndexAuto} ${classes.metricDialog}`}
        >
            <DialogTitle>
                <Grid container className={classes.metricDialogHeaderContainer}>
                    <Grid item xs={10} className={classes.metricDialogTitleWrapper}>
                        <Typography variant={'title'}>
                            { t('locationMap.chargingPowerInfo') }
                        </Typography>
                    </Grid>
                    <Grid item xs={2} className={classes.metricDialogClose}>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={() => {
                                setToggleMetricsDialog(false)
                            }}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                    </Grid>
                </Grid>
            </DialogTitle>
            <DialogContent>
                <Grid container>
                    <Grid item xs={12}>
                        <DialogContentText className={classes.metricDialogText}>
                            { t('locationMap.chargingPowerInfoText') }
                        </DialogContentText>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions className={classes.metricDialogFooter}>
                <Grid item xs={12} className={classes.bottomPart}>
                    <div className={classes.buttonsWrapper}>
                        <Button variant="outlined"
                                className={classes.bigCloseButton}
                                onClick={() => {
                                    setToggleMetricsDialog(false)
                                }}
                        >
                            { t('locationMap.close') }
                        </Button>
                    </div>
                </Grid>
            </DialogActions>
        </Dialog>
    )
}

export default DialogMetrics