import {useDispatch, useSelector} from 'react-redux'
import {setFieldValueOfMap} from '../store/features/Map'
import {RootState} from './../store'

const useHasChanges = () => {
    const dispatch = useDispatch()
    const hasChanges = useSelector((state: RootState) => state.map.hasChanges)
    const setHasChanges = (value) => {
        dispatch(setFieldValueOfMap({
            field: 'hasChanges',
            value
        }))
    }

    return {
        hasChanges,
        setHasChanges
    }
}

export default useHasChanges