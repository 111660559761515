import {RootState} from './../store'
import {useDispatch, useSelector} from 'react-redux'
import {setFieldValueOfMap} from "../store/features/Map"

const useZoomLevel = () => {
    const dispatch = useDispatch()
    const zoomLevel = useSelector((state: RootState) => state.map.zoomLevel)
    const setZoomLevel = (value: number) => {
        dispatch(setFieldValueOfMap({
            field: 'zoomLevel',
            value
        }))
    }

    return {
        zoomLevel,
        setZoomLevel
    }
}

export default useZoomLevel