import {makeStyles} from '@mui/styles';
import {Theme} from '@mui/material'

// @ts-ignore
export default makeStyles((theme: Theme) => ({
    button: {
        '&.MuiButtonBase-root': {
            margin: 0,
            transform: 'scale(1.25)'
        }
    },
    icon: {
    }
}));