import React from 'react'
import useStyles from "./styles";
import {CircularProgress, Typography, Box} from "@mui/material";

function Cluster({cluster, supercluster, latitude, longitude, pointCount, mapRef, points}) {
    const classes = useStyles()

    const connectorsRation = () => {
        const data = points.map(el => {
            return {
                'available': el.properties.available,
                'total': el.properties.total
            }
        })
        const total = data.map(el => el.total).reduce((a, b) => a + b)
        const available = data.map(el => el.available).reduce((a, b) => a + b)
        return Number(((available / total) * 100).toFixed(2))
    }

    return (
        <div className={classes.clusterWrapper}>
            <div className={classes.cluster}
                 onClick={() => {
                     const expansionZoom = Math.min(
                         supercluster.getClusterExpansionZoom(cluster.id),
                         20
                     )
                     mapRef.current.setZoom(expansionZoom)
                     mapRef.current.panTo({lat: latitude, lng: longitude})
                 }}
            >
                <Box className={classes.clusterText}
                     position="relative"
                     display="flex"
                     boxShadow={2}
                     borderRadius="50%"
                >
                    <CircularProgress
                        variant="determinate"
                        className={classes.bottom}
                        thickness={3}
                        value={100}
                    />
                    <CircularProgress variant="determinate"
                                      value={connectorsRation()}
                                      thickness={3}
                                      className={classes.top}
                    />
                    <Box top={0}
                         left={0}
                         bottom={0}
                         right={0}
                         position="absolute"
                         display="flex"
                         alignItems="center"
                         justifyContent="center"
                    >
                        <Typography className={classes.countNumber}>
                            <strong>{pointCount}</strong>
                        </Typography>
                    </Box>
                </Box>
            </div>
        </div>
    );
}

export default Cluster;