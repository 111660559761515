import {makeStyles} from '@mui/styles';
import {Theme} from '@mui/material'
import { grey } from '@mui/material/colors';

// @ts-ignore
export default makeStyles((theme: Theme) => ({
    container: {
        width: '100%'
    },
    title: {
        height: '48px',
        background: grey["A100"],
        color: grey[700],
        display: 'flex',
        alignItems: 'center',
        paddingLeft: '1rem',
        textTransform: 'uppercase'
    },
    locationDetailsInfo: {
        lineHeight: 2
    },
    locationDetailsInfoLeft: {
        width: '60%',
        maxWidth: '60%',
        textAlign: 'left'
    },
    locationDetailInfoLeftWrapper: {
        '&.MuiListItem-root': {
            paddingLeft: 0,
            paddingRight: '20px',
            textAlign: 'right'

        },
        '& .MuiListItemSecondaryAction-root': {
            right: 0
        }
    },
    locationDetailsInfoRight: {
        textAlign: 'right',
        '&.MuiListItemButton-root': {
            textAlign: 'right',
            paddingLeft: 0
        },
        '& .MuiTypography-root': {
            whiteSpace: 'nowrap',
            fontWeight: theme.typography.fontWeightBold,
            overflow: 'hidden',
            textOverflow: 'ellipsis'
        }
    },
    arrowRight: {
        '& .MuiSvgIcon-root': {
            color: theme.palette.common.black,
            fontSize: '1rem'
        }
    }
}));