import {useDispatch, useSelector} from 'react-redux'
import {setFieldValueOfLocation} from '../store/features/Location'
import {RootState} from './../store'
import {ILocationsList} from './../interface/locationsList'

const usePendingChargingState = () => {
    const dispatch = useDispatch()

    const pendingChargingState = useSelector((state: RootState) => state.location.pendingChargingState)
    const setPendingChargingState = (value: ILocationsList | boolean) => {
        dispatch(setFieldValueOfLocation({
            field: 'pendingChargingState',
            value
        }))
    }

    return {
        pendingChargingState,
        setPendingChargingState
    }
}

export default usePendingChargingState