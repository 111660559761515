import {
    Typography,
    Avatar,
    Button,
    TextField,
    InputAdornment,
    CircularProgress,
    Grid,
    DialogTitle,
    Paper,
    DialogContent,
    DialogContentText,
    DialogActions,
    Dialog,
    IconButton
} from '@mui/material'
import useStyles from './styles'

import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction'
import ListItemAvatar from '@mui/material/ListItemAvatar'

import {useDispatch, useSelector} from 'react-redux'
import {getLocationsList, searchLocationsList} from '../../api'

import {setFieldValueOfLocation} from '../../store/features/Location'
import {setFieldValueOfBasic} from '../../store/features/Basic'
import React, {useEffect, useRef, useState} from 'react'
import {buildQuery, getZoomLevel} from '../../utils'

import {RootState} from './../../store'
import moment from 'moment'

import {useInView} from 'react-intersection-observer'
import useLocationsList from '../../hooks/useLocationsList'
import usePlacesList from '../../hooks/usePlacesList'
import useChildClicked from './../../hooks/useChildClicked'
import useSelectedPlace from './../../hooks/useSelectedPlace'
import {ILocationListItem, ILocationsList} from '../../interface/locationsList'

import {IOpeningHoursItem} from '../../interface/openingHours'
import {getClientZoneUrl, getColor} from "../../utils"
import useHasChanges from "../../hooks/useHasChanges"
import useIsLoadMoreVisible from "../../hooks/useIsLoadMoreVisible"
import {useTranslation} from "react-i18next"
import useFilterChanged from "../../hooks/useFilterChanged"

import {useDebounce} from "use-debounce"

import {Cancel, Search, LocationOn, ExpandLess, ExpandMore} from "@mui/icons-material"
import GwSilver from '../../assets/icons/icon-gw-silver.svg'
import PSilver from '../../assets/icons/icon-p-silver.svg'
import RwSilver from '../../assets/icons/icon-r-silver.svg'
import useCoordinates from "../../hooks/useCoordinates";
import useDrawerOpen from "../../hooks/useDrawerOpen";
import useConfirmDialogPlaces from "../../hooks/useConfirmDialogPlaces";
import useDefaultCenter from "../../hooks/useDefaultCenter";
import useCurrentLocation from "../../hooks/useCurrentLocation";
import useZoomLevel from "../../hooks/useZoomLevel";
import useSearch from "../../hooks/useSearch";
import useToggleSearchResultsList from "../../hooks/useToggleSearchResultsList"
import useFilterOptions from "../../hooks/useFilterOptions";
import useShowHideSearchResults from "../../hooks/useToggleList";

const LocationsList = ({isSearching}) => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const searchInput = useRef(null)
    const isLoading = useSelector((state: RootState) => state.basic.isLoading)
    const {isLoadMoreVisible, setIsLoadMoreVisible} = useIsLoadMoreVisible()
    const {filterChanged} = useFilterChanged()
    const {t} = useTranslation()
    const {filterOptions} = useFilterOptions()

    const {ref, inView} = useInView({
        threshold: 0
    })

    useEffect(() => {
        if (inView) {
            loadMore()
        }
    }, [inView])


    // Basic - isLoading
    const setIsLoading = (value: boolean) => {
        dispatch(setFieldValueOfBasic({
            field: 'isLoading',
            value
        }))
    }

    // Map - data
    const coordinates = useSelector((state: RootState) => state.map.coordinates)
    const limit = useSelector((state: RootState) => state.location.locationListLimit)

    const page = useSelector((state: RootState) => state.location.locationListPage)
    const setPage = (value: number) => {
        dispatch(setFieldValueOfLocation({
            field: 'locationListPage',
            value
        }))
    }

    // toggle list
    const { toggleList, setToggleList } = useShowHideSearchResults()

    // Search
    const {search, setSearch} = useSearch()
    const [debounceValue] = useDebounce(search, Number.parseInt(process.env.REACT_APP_MAP_DRAG_END_DEBOUNCE))
    const {hasChanges} = useHasChanges()

    // Location - locationsList
    const {selectedPlace, setSelectedPlace} = useSelectedPlace()
    const {locationsList, setLocationsList} = useLocationsList()
    const {toggleSearchResultsList, setToggleSearchResultsList} = useToggleSearchResultsList()
    const {placesList, setPlacesList} = usePlacesList()
    const {defaultCenter} = useDefaultCenter()
    const {setCoordinates} = useCoordinates()
    const {currentLocation} = useCurrentLocation()

    // Place confirm dialog
    const {isPlaceConfirmDialogOpen, setIsPlaceConfirmDialogOpen} = useConfirmDialogPlaces()

    useEffect(() => {
        setIsLoading(true)
        if (debounceValue && debounceValue.trim().length > 2) {
            setIsLoadMoreVisible(false)
            const query = selectedPlace ? `latitude=${selectedPlace.latitude}&longitude=${selectedPlace.longitude}&${buildQuery(filterOptions)}` : `q=${debounceValue}&${buildQuery(filterOptions)}`
            searchLocationsList(query).then(({locations, places}) => {
                setLocationsList(locations)
                setPlacesList(places)
            }).finally(() => {
                setIsLoading(false)
                setToggleSearchResultsList(true)
                searchInput.current?.focus()
                searchInput.current?.getElementsByTagName('input')[0].focus()
                setIsLoadMoreVisible(true)
            })
        } else {
            setIsLoading(false)
        }
    }, [debounceValue])

    // Map - child clicked
    const {childClicked, setChildClicked} = useChildClicked()

    useEffect(() => {
        return () => {
            if (coordinates.lat && coordinates.lng && !childClicked) {
                const resetPage = 1
                setPage(resetPage)
            }
        }
    }, [hasChanges])

    useEffect(() => {
        const resetPage = 1
        setPage(resetPage)
    }, [filterChanged])

    const loadMore = () => {
        const nextPage = page + 1
        setPage(nextPage)
        loadMoreData(coordinates, nextPage, limit)
    }

    const loadMoreData = (coordinates: { lat: number, lng: number }, page: number, limit: number) => {
        setIsLoading(true)

        getLocationsList(search, coordinates, page, limit, filterOptions)
            .then((data: ILocationsList) => {
                if (typeof data !== 'undefined' && data.length) {
                    setLocationsList([...locationsList, ...data])
                } else {
                    setIsLoadMoreVisible(false)
                }
            }).finally(() => {
                setIsLoading(false)
            })
    }

    const getOpeningHoursInfo = (item: IOpeningHoursItem) => {
        if (item?.twenty_four_seven) {
            return t('locationMap.open24_7')
        }

        if (item?.open_now) {
            return item['changes_at'] ? `${t('locationMap.openUntil')} ${moment(item['changes_at'][0]).format('HH:mm')}` : t('locationMap.open')
        }

        return item['changes_at'] ? `${t('locationMap.openFrom')} ${moment(item['changes_at'][0]).format('HH:mm')}` : t('locationMap.closed')
    }

    const isAvailable = (item) => {
        switch (item['availability']) {
            case 'available':
                return true
            default:
                return false
        }
    }

    const getPhoto = (defaultPhoto: string, ownerType: string) => {
        if(defaultPhoto) {
            return `${defaultPhoto}?w=200`
        }
        switch (ownerType?.toLowerCase()) {
            case 'partner':
                return PSilver
            case 'roaming':
                return RwSilver
            case 'greenway':
            default:
                return GwSilver
        }
    }

    const {setDrawerOpen} = useDrawerOpen()
    const {zoomLevel, setZoomLevel} = useZoomLevel()

    const placeClicked = (item) => {
        setZoomLevel(getZoomLevel(zoomLevel))
        setTimeout(() => {
            setSelectedPlace(item)
            const center = {
                lat: Number(item.latitude),
                lng:  Number(item.longitude),
            }
            setDrawerOpen(false)
            setCoordinates(center)
            setSearch(item.name)
        }, 1000)
    }

    const triggerChildClick = (item) => {
        setZoomLevel(getZoomLevel(zoomLevel))
        setTimeout(() => {
            setChildClicked(null)
            setChildClicked(item['location_id'])
        }, 1000)
    }

    const disableSearch = (value) => {
        if (!value) {
            searchInput.current?.blur()
            searchInput.current?.removeAttribute('autofocus')
            window.scrollTo({top: 0, left: 0, behavior: 'smooth'})
        }
    }

    const cancelPlace = () => {
        setSearch('')
        disableSearch(false)
        setToggleList(true)
        setCoordinates(currentLocation ? currentLocation : defaultCenter)
        setSelectedPlace(null)
        setPlacesList(null)
        setLocationsList(null)
        setIsPlaceConfirmDialogOpen(false)
        setToggleSearchResultsList(false)
    }

    const confirmCancelPlace = () => {
        setIsPlaceConfirmDialogOpen(true)
    }

    return (
        <>
            {
                toggleList && toggleSearchResultsList && (
                    <Paper className={classes.container}
                           elevation={3}
                    >
                        {
                            <Grid item xs={12} className={`${classes.filterInputWrapper} ${classes.wrapperSticky}`}>
                                <TextField
                                    classes={{root: classes.searchInputField}}
                                    size="small"
                                    type="text"
                                    ref={searchInput}
                                    placeholder={t('locationMap.search')}
                                    onKeyDown={(event) => {
                                        if (event.key === 'Enter') {
                                            selectedPlace ? confirmCancelPlace() : setSearch(event.currentTarget.value)
                                            event.preventDefault()
                                        }
                                    }}
                                    onClick={() => {
                                        setTimeout(() => {
                                            const origin = getClientZoneUrl(window.location.origin).toString()
                                            window.parent.postMessage('scrollTop', origin)
                                        }, 500)
                                    }}
                                    disabled={isLoading}
                                    onChange={(event) => {
                                        selectedPlace ? confirmCancelPlace() : setSearch(event.currentTarget.value)
                                    }}
                                    autoFocus={true}
                                    value={search}
                                    InputProps={{
                                        autoComplete: 'off',
                                        startAdornment: (
                                            <InputAdornment position="start" className={classes.iconStyle}>
                                                {selectedPlace ? <LocationOn/> : <Search/>}
                                            </InputAdornment>
                                        ),
                                        endAdornment: (
                                            isLoading ? (
                                                <InputAdornment position="end" className={classes.iconStyle}>
                                                    <CircularProgress color="primary"
                                                                      size={20}
                                                    />
                                                </InputAdornment>
                                            ) : search.trim().length ? (
                                                <InputAdornment position="end" className={classes.iconStyle}>
                                                    <Cancel  className={`${classes.cancelSearchButton} ${selectedPlace ? classes.selectedPlaceCancelButton : ''}`}
                                                             onClick={() => {
                                                                 selectedPlace ? confirmCancelPlace() : setSearch('')
                                                             }}
                                                    />
                                                </InputAdornment>
                                            ) : ''
                                        )
                                    }}
                                />
                            </Grid>
                        }
                        {
                            placesList && !selectedPlace && (
                                <>
                                    <List className={classes.placesWrapper} dense>
                                        {placesList?.map((item: ILocationListItem, i: number) => {
                                            return (
                                                <div className={`${classes.placeItemWrapper} ${isSearching ? classes.isSearching : ''}`}
                                                     key={`place-${i}`}
                                                >
                                                    <ListItem className={classes.placeItem}
                                                              onClick={() => {
                                                                  return isSearching ? ()=>{} : placeClicked(item)
                                                              }}
                                                              sx={{paddingLeft: 0, padding: '0', margin: '10px 0'}}
                                                    >
                                                        <LocationOn className={classes.locationOn}/>
                                                        <Typography variant={'body1'} className={classes.placeTitle}>
                                                            <span className={classes.placeTitleSpan}>{item['address']}</span>
                                                        </Typography>
                                                    </ListItem>
                                                </div>
                                            )
                                        })}
                                    </List>
                                </>
                            )
                        }
                        {
                            (locationsList && locationsList.length > 0) && (
                                <>
                                    <List dense>
                                        {locationsList?.map((item: ILocationListItem, i: number) => {
                                            return (
                                                <div className={`${classes.listItemWrapper} ${isSearching ? classes.isSearching : ''}`}
                                                     key={`${item['location_id']}-${i}`}
                                                >
                                                    <ListItem className={classes.listItem}
                                                              onClick={() => {
                                                                  return isSearching ? ()=>{} : triggerChildClick(item)
                                                              }}
                                                              sx={{paddingLeft: 0, padding: '0', margin: '10px 0'}}
                                                    >
                                                        <ListItemAvatar>
                                                            <Avatar alt={item.name}
                                                                    src={getPhoto(item['default_photo'], item['owner_type'])}
                                                                    variant="rounded"
                                                                    sx={{width: 60, height: 60, marginRight: '15px'}}
                                                                    className={`${classes.avatar} ${item['default_photo'] === null ? classes.avatarFallbackImage : ''}` }
                                                            />
                                                        </ListItemAvatar>
                                                        <div className={classes.locationInfoWrapper}>
                                                            <Typography variant={'subtitle1'} className={classes.titleWrapper}>
                                                                <span className={classes.title}>{item['name']}</span>
                                                            </Typography>
                                                            <br/>
                                                            <Typography variant={'caption'} className={classes.ownerType}>
                                                                {
                                                                    item['owner_type'] === 'Greenway' ? t('locationMap.greenWay') : item['owner_type']
                                                                }
                                                            </Typography>
                                                            <br/>
                                                            <Typography variant={'caption'} className={classes.openingHours}>
                                                                {getOpeningHoursInfo(item)}
                                                            </Typography>
                                                        </div>
                                                        <ListItemSecondaryAction
                                                            className={classes.ListItemSecondary}
                                                            sx={{right: 0, lineHeight: 1}}
                                                        >
                                                            <div className={classes.locationAdditionalInfoWrapper}>
                                                                <div className={classes.availabilityWrapper}>
                                                                    {
                                                                        isAvailable(item) && (
                                                                            <Typography variant={'subtitle1'}
                                                                                        className={`${getColor(item['availability'], classes)} ${classes.availabilityLeft} ${classes.availability}`}
                                                                            >
                                                                                {item.available}/{item.total}
                                                                            </Typography>
                                                                        )
                                                                    }
                                                                    <Typography variant={'subtitle1'}
                                                                                className={`${getColor(item['availability'], classes)} ${classes.availabilityRight} ${classes.availability}`}
                                                                    >
                                                                        { t(`locationMap.${item['availability']}`) }
                                                                    </Typography>
                                                                </div>
                                                                <Typography variant={'subtitle1'} className={classes.maxPower}>
                                                                    { item['max_power'] ? item['max_power'] : '? ' }{t('locationMap.kW')}
                                                                </Typography>
                                                                <Typography variant={'caption'} className={classes.distance}>
                                                                    {item.distance.toFixed(2)} {t('locationMap.km')}
                                                                </Typography>
                                                            </div>
                                                        </ListItemSecondaryAction>
                                                    </ListItem>
                                                </div>
                                            )
                                        })}
                                    </List>
                                    {
                                        !locationsList && (
                                            <Typography variant="subtitle1">
                                                {t('locationMap.noResults')}
                                            </Typography>
                                        )
                                    }
                                    {
                                        isLoadMoreVisible && !isLoading ?
                                            (
                                                <Button variant="text"
                                                        onClick={() => {
                                                            loadMore()
                                                        }}
                                                        ref={ref}
                                                >
                                                    {t('locationMap.loadMore')}
                                                </Button>
                                            ) : (
                                                isLoadMoreVisible && (
                                                    <Grid item xs={12}
                                                          className={classes.loadingText}
                                                    >
                                                        <Typography variant="subtitle1">
                                                            { t('locationMap.loading') }
                                                        </Typography>
                                                    </Grid>
                                                )
                                            )
                                    }
                                </>
                            )
                        }
                    </Paper>
                )
            }
            {
                (toggleSearchResultsList && locationsList && locationsList.length > 0) && (
                    <>
                        <Grid item xs={12} className={`${classes.toggleList} ${toggleList ? '' : classes.toggledList}`}>
                            <Paper elevation={3}>
                                <IconButton aria-label="delete"
                                            size="small"
                                            color={"primary"}
                                            onClick={() => {
                                                setToggleList(!toggleList)
                                            }}
                                >
                                    {
                                        toggleList ? <ExpandLess /> : <ExpandMore/>
                                    }
                                </IconButton>
                            </Paper>
                        </Grid>
                    </>
                )
            }
            {
                (locationsList && locationsList.length === 0) && (
                    <>
                        <Paper className={classes.container}
                               elevation={3}
                        >
                            <Grid item xs={12}
                                  className={classes.loadingText}
                            >
                                <Typography variant="h5" class={classes.redColor}>
                                    {t('locationMap.noLocationFound')}
                                </Typography>
                            </Grid>
                        </Paper>
                    </>
                )
            }
            {
                <Dialog open={isPlaceConfirmDialogOpen}
                        onClose={() => {}}
                >
                    <DialogTitle>
                        <Typography variant={'body1'} className={classes.confirmDialogTitle}>
                            {t('locationMap.exitSearchedLocation')}
                        </Typography>
                    </DialogTitle>
                    <DialogContent className={classes.confirmDialogContent}>
                        <DialogContentText>
                            <Typography variant={'subtitle2'} className={classes.confirmDialogText}>
                                {t('locationMap.confirmationPlaceDialogText')}
                            </Typography>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions className={classes.confirmDialogActions}>
                        <Button className={classes.confirmDialogCancelButton}
                                variant="outlined"
                                onClick={() => {
                                    setIsPlaceConfirmDialogOpen(false)
                                }}
                        >
                            <Typography variant={'body2'}>
                                {t('locationMap.cancel')}
                            </Typography>
                        </Button>
                        <Button className={classes.confirmDialogSubmitButton}
                                color="error"
                                variant="contained"
                                onClick={() => {
                                    cancelPlace()
                                }}
                        >
                            <Typography variant={'body2'}>
                                {t('locationMap.yesExit')}
                            </Typography>
                        </Button>
                    </DialogActions>
                </Dialog>
            }
        </>
    )
}

export default LocationsList
