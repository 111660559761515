import useStyles from './styles'
import React, {RefObject, useEffect, useState} from 'react'
import {Drawer, List, ListItemText, ListItemButton, Typography, Switch, Avatar, Divider, Fab} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import DoneIcon from '@mui/icons-material/Done'
import LayersIcon from '@mui/icons-material/Layers';
import {setFieldValueOfMap} from '../../../../store/features/Map'
import {useDispatch} from 'react-redux'
import ListItemAvatar from '@mui/material/ListItemAvatar'

import roadmapImage from '../../../../assets/map/ic_default-2x.png'
import satelliteImage from '../../../../assets/map/ic_satellite-2x.png'
import terrainImage from '../../../../assets/map/ic_terrain-2x.png'
import trafficImage from '../../../../assets/map/ic_traffic-2x.png'
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux"
import {RootState} from './../../../../store'
import useMediaQuery from "@mui/material/useMediaQuery";

interface IMapTypeController {
    mapRef: RefObject<any>
}

const MapTypeController = ({mapRef}: IMapTypeController) => {
    const classes = useStyles()
    const [activeLayer, setActiveLayer] = useState('roadmap')
    const [state, setState] = useState(false)
    const dispatch = useDispatch()
    const {t} = useTranslation()
    const isDesktop = useMediaQuery('(min-width: 900px)')

    const trafficLayer = useSelector((state: RootState) => state.map.trafficLayer)
    const [isTrafficLayerSet, setIsTrafficLayerSet] = useState(false);
    useEffect(() => {
        trafficLayer.length ? setIsTrafficLayerSet(true) : setIsTrafficLayerSet(false)
    }, [trafficLayer])

    const setTrafficLayer = (value: boolean) => {
        let newValue: string[] = []
        if (value) {
            newValue = ['TrafficLayer']
        }

        dispatch(setFieldValueOfMap({
            field: 'trafficLayer',
            value: newValue
        }))
    }

    const setMapType = (type: string) => {
        if (mapRef?.current) {
            mapRef.current.setMapTypeId(type)
            setActiveLayer(type)
            setState(false)
            if(type === 'satellite') {
                setTrafficLayer(false)
            }
        }
    }

    return (
        <div className={isDesktop ? classes.mapControllerWrapper : classes.mapControllerWrapperMobile}>
            <Fab className={classes.layerIcon}
                 onClick={() => {
                     setState(true)
                 }}
            >
                <LayersIcon/>
            </Fab>
            <Drawer anchor={'bottom'}
                    open={state}
                    classes={{
                        paper: classes.drawer
                    }}
                    onClose={() => {
                        setState(false)
                    }}
            >

                <button onClick={() => {
                    setState(false)
                }}
                        className={classes.closeIcon}
                >
                    <CloseIcon/>
                </button>
                <Typography className={classes.title}
                            variant={'subtitle2'}>
                    {t('locationMap.mapLayer')}
                </Typography>
                <Divider className={classes.divider}/>
                <List className={classes.listWrapper}>
                    <ListItemButton
                        onClick={() => {
                            setMapType('roadmap')
                        }}
                        selected={activeLayer === 'roadmap'}
                        classes={{selected: classes.selected}}
                    >
                        <ListItemAvatar>
                            <Avatar src={roadmapImage} alt={t('locationMap.roadMap')} variant="rounded"/>
                        </ListItemAvatar>
                        <ListItemText primary={t('locationMap.roadMap')}/>
                        {
                            activeLayer === 'roadmap' &&
                            <DoneIcon color={'secondary'}/>
                        }
                    </ListItemButton>
                    <ListItemButton
                        onClick={() => {
                            setMapType('satellite')
                        }}
                        selected={activeLayer === 'satellite'}
                        classes={{selected: classes.selected}}
                    >
                        <ListItemAvatar>
                            <Avatar src={satelliteImage} alt={t('locationMap.satellite')} variant="rounded"/>
                        </ListItemAvatar>
                        <ListItemText primary={t('locationMap.satellite')}/>
                        {
                            activeLayer === 'satellite' &&
                            <DoneIcon color={'secondary'}/>
                        }
                    </ListItemButton>
                    <ListItemButton
                        onClick={() => {
                            setMapType('terrain')
                        }}
                        selected={activeLayer === 'terrain'}
                        classes={{selected: classes.selected}}
                    >
                        <ListItemAvatar>
                            <Avatar src={terrainImage} alt={t('locationMap.terrain')} variant="rounded"/>
                        </ListItemAvatar>
                        <ListItemText primary={t('locationMap.terrain')}/>
                        {
                            activeLayer === 'terrain' &&
                            <DoneIcon color={'secondary'}/>
                        }
                    </ListItemButton>
                    <ListItemButton classes={{selected: classes.selected}}
                                    disabled={mapRef?.current.mapTypeId === 'satellite'}
                    >
                        <ListItemAvatar>
                            <Avatar src={trafficImage} alt={t('locationMap.traffic')} variant="rounded"/>
                        </ListItemAvatar>
                        <ListItemText primary={t('locationMap.traffic')}/>
                        <Switch
                            checked={isTrafficLayerSet}
                            onChange={(_, checked) => setTrafficLayer(checked)}
                            color="secondary"
                        />
                    </ListItemButton>
                </List>
            </Drawer>
        </div>
    )
}

export default MapTypeController
