import {useDispatch, useSelector} from 'react-redux'
import {setFieldValueOfLocation} from '../store/features/Location'
import {RootState} from './../store'

const useToggleCurrentStates = () => {
    const dispatch = useDispatch()

    const toggleCurrentState = useSelector((state: RootState) => state.location.toggleCurrentState)
    const setToggleCurrentStates = (value: boolean) => {
        dispatch(setFieldValueOfLocation({
            field: 'toggleCurrentState',
            value
        }))
    }

    return {
        toggleCurrentState,
        setToggleCurrentStates
    }
}

export default useToggleCurrentStates