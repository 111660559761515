import {useDispatch, useSelector} from 'react-redux'
import {setFieldValueOfLocation} from '../store/features/Location'
import {RootState} from './../store'
import {IPlacesList} from './../interface/placesList'

const usePlacesList = () => {
    const dispatch = useDispatch()

    const placesList = useSelector((state: RootState) => state.location.placesList)
    const setPlacesList = (value: IPlacesList | []) => {
        dispatch(setFieldValueOfLocation({
            field: 'placesList',
            value
        }))
    }

    return {
        placesList,
        setPlacesList
    }
}

export default usePlacesList