import {makeStyles} from '@mui/styles';
import {Theme} from '@mui/material'

// @ts-ignore
export default makeStyles((theme: Theme) => ({
    available: {
        backgroundColor: theme.palette.primary.main
    },
    offline: {
        backgroundColor: theme.palette.grey[700]
    },
    error: {
        backgroundColor: theme.palette.error.main
    },
    charging: {
        backgroundColor: theme.palette.secondary.main
    },
    topSection: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderRadius: '16px 16px 0 0',
        color: theme.palette.common.white,
        '& .MuiTypography-root': {
            color: theme.palette.common.white
        },
        [theme.breakpoints.up("md")]: {
            borderRadius: 0
        }
    },
    topLeftSection: {
        display: 'flex',
        alignItems: 'center',
        paddingLeft: '1rem',
        paddingTop: '5px',
        textTransform: 'uppercase',
        '& .MuiTypography-root': {
            fontWeight: theme.typography.fontWeightBold,
            fontSize: '0.75rem'
        }
    },
    topRightSection: {
        display: 'flex',
        alignItems: 'center',
        paddingRight: '1rem',
        paddingTop: '5px',
        '& .MuiSvgIcon-root': {
            color: theme.palette.grey[300]
        }
    },
}));