import {makeStyles} from '@mui/styles';
import {Theme} from '@mui/material'

export default makeStyles((theme: Theme) => ({
    containerDesktop: {
        '& .MuiPaper-root': {
            width: '40%',
            '& .MuiBox-root': {
                top: '3px'
            }
        }
    },
    container: {
        '& .MuiPaper-root': {
            borderRadius: '16px 16px 0 0',
        },
        padding: '25px',
        '&.MuiDrawer-root > .MuiPaper-root': {
            height: `95%`,
            overflow: 'visible',
        }
    },
    closeButton: {
        paddingTop: '7px',
        paddingRight: '1rem',
        textAlign: 'right',
        '& .MuiSvgIcon-root': {
            color: theme.palette.grey[300]
        }
    },
    topSection: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '& .MuiTypography-root': {
            fontWeight: theme.typography.fontWeightBold,
            color: theme.palette.text.primary
        }
    },
    accessInstructionsWrapper: {},
    contentWrapper: {
        overflow: 'auto',
        height: '70vh',
        padding: '0 5px'
    },
    divider: {
        '&.MuiDivider-root': {
            marginBottom: '25px'
        }
    },
    description: {
      '&.MuiTypography-root': {
          fontSize: '0.875rem',
          margin: '25px 5px'
      }
    },
    title: {
        textAlign: 'center',
        '& .MuiTypography-root': {
            color: theme.palette.common.black,
            fontWeight: theme.typography.fontWeightBold,
        }
    },
    accessInstructions: {
        '& .MuiTypography-root': {
            textAlign: 'justify'
        }
    },
    topRightSection: {
        display: 'flex',
        alignItems: 'center',
        paddingRight: '1rem',
        paddingTop: '5px',
        '& .MuiSvgIcon-root': {
            color: theme.palette.grey[300]
        }
    },
    imageWrapper: {
        overflow: 'hidden',
        maxHeight: '175px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '& img': {
            objectFit: 'cover',
            width: '100%',
            height: '100%'
        }
    }
}))