import React from 'react'
import {
    Divider,
    Grid,
    IconButton,
    List,
    ListItem, ListItemButton,
    ListItemText,
    Typography
} from "@mui/material"
import useStyles from "./styles"
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import useToggleOpeningHours from "../../../../hooks/useToggleOpeningHours"
import {useTranslation} from "react-i18next";

const LocationDetails = ({place, openingHoursInfo}) => {
    const classes = useStyles()
    const { t } = useTranslation()
    const {setToggleOpeningHours} = useToggleOpeningHours()

    const redirectToPriceList = () => {
        window.open(process.env.REACT_APP_PRICE_LIST_URL, '_blank')
        return false
    }

    return (
        <>
            {
                place && (
                    <div className={classes.container}>
                        <Grid container>
                            <Grid item xs={12} className={classes.title}>
                                <Typography variant={"subtitle2"}>
                                    {t('locationMap.locationDetails')}
                                </Typography>
                            </Grid>
                        </Grid>
                        <List component="nav" className={classes.locationDetailsInfo}>
                            <ListItem>
                                <ListItemText primary={t('locationMap.locationName')} className={classes.locationDetailsInfoLeft}/>
                                <ListItemText primary={place?.name} className={classes.locationDetailsInfoRight}/>
                            </ListItem>
                            <Divider/>
                            <ListItem divider>
                                <ListItemText primary={t('locationMap.locationOwner')} className={classes.locationDetailsInfoLeft}/>
                                <ListItemText primary={place?.cpo?.owner_name}
                                              className={classes.locationDetailsInfoRight}/>
                            </ListItem>
                            <ListItemButton
                                onClick={() => {
                                    setToggleOpeningHours(true)
                                }}
                            >
                                <ListItem   className={classes.locationDetailInfoLeftWrapper}
                                            secondaryAction={
                                                <IconButton edge="end" aria-label="moreInfo" className={classes.arrowRight}>
                                                    <ArrowForwardIosIcon/>
                                                </IconButton>
                                            }
                                >
                                    <ListItemText primary={t('locationMap.openingHours')} className={classes.locationDetailsInfoLeft}/>
                                    <ListItemText primary={openingHoursInfo} className={classes.locationDetailsInfoRight}/>
                                </ListItem>
                            </ListItemButton>
                            <Divider light/>
                            <ListItemButton onClick={(e) => {
                                                e.preventDefault()
                                                redirectToPriceList()
                                            }}
                            >
                                <ListItem   className={classes.locationDetailInfoLeftWrapper}
                                            secondaryAction={
                                                <IconButton edge="end" aria-label="moreInfo" className={classes.arrowRight}>
                                                    <ArrowForwardIosIcon/>
                                                </IconButton>
                                            }
                                >
                                    <ListItemText primary={t('locationMap.priceList')} className={classes.locationDetailsInfoLeft}/>
                                    <ListItemText primary={t('locationMap.showDetails')} className={classes.locationDetailsInfoRight}/>
                                </ListItem>
                            </ListItemButton>
                        </List>
                    </div>
                )
            }
        </>
    )
}

export default LocationDetails
