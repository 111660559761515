import React, {useEffect, useState} from "react"
import useStyles from "./styles"
import QrReader from 'react-qr-scanner'
import {
    Grid,
    Button,
    Select,
    Dialog,
    MenuItem,
    Typography,
    IconButton,
    InputLabel,
    FormControl,
    DialogTitle,
    DialogContent,
    DialogActions,
    DialogContentText,
    SelectChangeEvent
} from "@mui/material"
import CloseIcon from '@mui/icons-material/Close'
import useToggleScanQR from "../../../../hooks/useToggleScanQR"
import useToggleSearchLocation from "../../../../hooks/useToggleSearchLocation"
import {useTranslation} from "react-i18next"
import {getClientZoneUrl, getParamValue} from "../../../../utils"
import useMediaQuery from "@mui/material/useMediaQuery"

const Scanner = () => {
    const classes = useStyles()
    const {toggleScanQR, setToggleScanQR} = useToggleScanQR()
    const {setToggleSearchLocation} = useToggleSearchLocation()
    const isDesktop = useMediaQuery('(min-width: 900px)')
    const {t} = useTranslation()

    const [deviceOptionsList, setDeviceOptionsList] = useState([]);
    const [selectedDeviceId, setDeviceId] = useState(null)

    const handleChange = (event: SelectChangeEvent) => {
        setDeviceId(null)
        setTimeout(() => {
            setDeviceId(event.target.value)
        }, 500)
    }
    const createCameraList = (devices, exception) => {
        let counter = 1
        let deviceOptions = []
        for(let i = 0; i < devices.length; i ++){
            let device = devices[i]
            if (device.kind === 'videoinput') {
                if(device.label.toLowerCase().indexOf('back') > -1) {
                    let option = document.createElement('option')
                    option.value = device.deviceId
                    option.text = `${t('locationMap.camera')} ${counter}`
                    deviceOptions.push(option)
                    counter = counter + 1
                } else if(device.label.indexOf('back') === -1 && exception) {
                    let option = document.createElement('option')
                    option.value = device.deviceId
                    option.text = `${t('locationMap.camera')} ${counter}`
                    deviceOptions.push(option)
                    counter = counter + 1
                }
            }
        }
        // it there is no back camera, then add what is available
        if(deviceOptions.length === 0) {
            return createCameraList(devices, true)
        } else {
            return deviceOptions
        }
    }
    useEffect(() => {
        if(toggleScanQR) {
            setDeviceOptionsList([])
            navigator.mediaDevices?.getUserMedia({
                audio: false,
                video: { facingMode: "environment" }
            }).then(() => {
                navigator.mediaDevices.enumerateDevices().then(function (devices) {
                    const deviceOptions = createCameraList(devices, false)
                    setDeviceOptionsList(deviceOptions)
                })
            })
        }
    }, [toggleScanQR])

    return (
        <div>
            {
                <Dialog open={toggleScanQR}
                        fullScreen={true}
                        className={classes.wrapper}
                >
                    <DialogTitle>
                        <Grid item xs={12} className={classes.topSection}>
                            <IconButton
                                edge="start"
                                color="inherit"
                                onClick={() => {
                                    setDeviceId(null)
                                    setToggleScanQR(false)
                                }}
                                aria-label={t('locationMap.close')}
                                className={classes.closeButton}
                            >
                                <CloseIcon/>
                            </IconButton>
                        </Grid>
                        <Grid item xs={12} className={classes.topSectionTitle}>
                            {t('locationMap.scanQRonCharger')}
                        </Grid>
                    </DialogTitle>
                    <DialogContent>
                        <Grid container className={classes.bottomSection}>
                            <Grid item xs={12}>
                                <FormControl fullWidth>
                                    <InputLabel className={classes.selectLabel}>
                                        {t('locationMap.selectCamera')}
                                    </InputLabel>
                                    <Select id="videoSource"
                                            onChange={handleChange}
                                            value={selectedDeviceId ? selectedDeviceId : ''}
                                            className={classes.selectCamera}
                                    >
                                        {
                                            deviceOptionsList.map((option, index) => (
                                                <MenuItem value={option.value}
                                                          key={index}
                                                >
                                                    {option.text}
                                                </MenuItem>
                                            ))
                                        }
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                            {
                                selectedDeviceId &&
                                <QrReader className={isDesktop ? classes.QrReaderDesktop : classes.QrReaderMobile}
                                          constraints={{
                                              audio: false,
                                              video: {
                                                  deviceId: selectedDeviceId
                                              }
                                          }}
                                          style={{
                                              paddingTop: 0,
                                              maxHeight: '320px',
                                              maxWidth: '100%'
                                          }}
                                          onError={(error) => {
                                              console.log(error)
                                          }}
                                          onScan={(result) => {
                                              if (!!result) {
                                                  // TODO - ked bude aj novy typ QR musime rozlisovat stare a nove
                                                  // na zaklade toho bude ina url
                                                  const connectorId = result['text'].substring(42, result['text'].length)
                                                  const origin = getClientZoneUrl(window.location.origin).toString()
                                                  const url = `${getClientZoneUrl(window.location.origin)}/charging?connectorCode=${connectorId}`

                                                  const isIFrame = Boolean(getParamValue('isIframe')) || false
                                                  if(isIFrame) {
                                                      window.parent.postMessage(`scanQrRedirect|${url}`, origin)
                                                  } else {
                                                      window.location.href = url
                                                  }
                                              }
                                          }}
                                />
                            }
                            </Grid>
                            {
                                !selectedDeviceId && (
                                    <Grid item xs={12}>
                                        <DialogContentText>
                                            {t('locationMap.scanQRText')}
                                        </DialogContentText>
                                    </Grid>
                                )
                            }
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Grid container className={classes.bottomSection}>
                            <Grid item xs={12}>
                                <DialogContentText>
                                    {t('locationMap.or')}
                                </DialogContentText>
                            </Grid>
                            <Grid item xs={11}>
                                <Button variant="outlined"
                                        onClick={() => {
                                            setToggleSearchLocation(true)
                                        }}
                                >
                                    <Typography variant={'subtitle2'}>
                                        {t('locationMap.typeCodeManually')}
                                    </Typography>
                                </Button>
                            </Grid>
                        </Grid>
                    </DialogActions>
                </Dialog>
            }
        </div>
    )
}

export default Scanner