import { makeStyles } from '@mui/styles';
import {Theme} from '@mui/material'

export default makeStyles((theme: Theme) => ({
    paper: {
        padding: '10px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        width: '100px'
    },
    mapContainer: {
        width: '100%',
        height: '85vh',
        '& .gm-style-cc': {
            display: 'none !important'
        },
        '& .gm-style a[href^="https://maps.google.com/maps"]': {
            display: 'none !important'
        },
        '& .gmnoprint': {
            display: 'none !important'
        }
    },
    showList: {
        width: '66.666667%',
    },
    hideList: {
        width: '100%'
    },
    mapContainerDesktop: {
        height: '100%',
        position: 'absolute',
        '& .gm-style-cc': {
            display: 'none !important'
        },
        '& .gm-style a[href^="https://maps.google.com/maps"]': {
            display: 'none !important'
        },
        '& .gmnoprint': {
            // marginBottom: '10vh !important'
        }
    },
    markerContainer: {
        position: 'absolute',
        transform: 'translate(-50%, -50%)',
        zIndex: 1,
        '&:hover': {
            zIndex: 2
        }
    },
    pointer: {
        cursor: 'pointer',
    },
    scanQRDesktop: {
        right: '1vw',
        bottom: '2vh',
        position: 'absolute',
        '& .MuiButtonBase-root': {
            transform: 'scale(1.4)'
        },
        '& .MuiAvatar-root': {
            background: theme.palette.secondary.main
        }
    },
    scanQRCurrentStates: {
        right: '1vw',
        bottom: '2.5vh',
        position: 'absolute',
        '& .MuiButtonBase-root': {
            transform: 'scale(1.4)'
        },
        '& .MuiAvatar-root': {
            background: theme.palette.secondary.main
        }
    },
    scanQRCurrentStatesMobile: {
        right: '3vw',
        bottom: '3vh',
        position: 'absolute',
        '& .MuiButtonBase-root': {
            transform: 'scale(1.4)'
        },
        '& .MuiAvatar-root': {
            background: theme.palette.secondary.main
        }
    },
    scanQR: {
        right: '2.5vw',
        bottom: '2vh',
        position: 'absolute',
        '& .MuiButtonBase-root': {
            transform: 'scale(1.4)'
        },
        '& .MuiAvatar-root': {
            background: theme.palette.secondary.main
        }
    },
    marker: {
        '&.isLoading': {
            opacity: 0.5
        }
    },
    geolocationInfoDesktop: {
        top: '35vh',
        zIndex: 1051,
        color: theme.palette.secondary.dark,
        width: '100%',
        textAlign: 'center',
        position: 'absolute',
    },
    geolocationInfo: {
        top: '35vh',
        zIndex: 1051,
        color: theme.palette.secondary.dark,
        width: '100%',
        textAlign: 'center',
        position: 'absolute',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '& h4': {
            width: '70%',
            fontSize: '0.875rem'
        }
    },
    enableGeolocation: {
        textAlign: 'center'
    },
    hide: {
        display: 'none'
    }
}));