import { makeStyles } from '@mui/styles';
import {Theme} from '@mui/material'

export default makeStyles((theme:Theme) => ({
    rectangle: {
        position: 'absolute',
        top: 0,
        right: 0
    },
    rectangleCurrentState: {
        background: theme.palette.common.black,
        color: theme.palette.common.white,
        position: 'absolute',
        top: 0,
        right: '-10px'
    },
    rectangleBlack: {
        position: 'absolute',
        top: 0,
        right: '-10px'
    },
    rectangleTextCurrentState: {
        fontWeight: theme.typography.fontWeightBold,
        padding: '0 1px'
    },
    rectangleText: {
        background: theme.palette.common.white,
        fontWeight: theme.typography.fontWeightBold,
        padding: '0 1px'
    },
    rectangleTextBlack: {
        color: theme.palette.common.white,
        background: theme.palette.common.black,
        fontWeight: theme.typography.fontWeightBold,
        padding: '0 1px'
    },
    markerContainer: {
        position: 'absolute',
        transform: 'translate(-10%, -80%)',
        zIndex: 2,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '&:hover': {
            zIndex: 2,
            transform: 'translate(-10%, -80%) scale(1.1)'
        },
        transition: 'opacity 0.5s',
        opacity: 1,
        '& .hide': {
            opacity: 0,
            pointerEvents: 'none',
            transition: 'opacity 0.5s'
        }
    },
    pointer: {
        width: '50px',
        height: '50px',
        cursor: 'pointer'
    },
    borderRadiusAround: {
        border: `3px solid ${theme.palette.common.white}`,
        borderRadius: '3px',
        padding: '0 2px'
    },
    borderRadiusLeft: {
        border: `3px solid ${theme.palette.common.white}`,
        borderRadius: '3px 0 0 3px'
    },
    borderRadiusRightBlack: {
        border: `3px solid ${theme.palette.common.black}`,
        borderRadius: '0 3px 3px 0'
    },
    backgroundBlack: {
        background: `${theme.palette.common.black}`
    },
    pinNumber: {
        color: 'white',
        fontSize: '0.6rem',
        position: 'absolute',
        top: '34%',
        fontWeight: 600,
        '&:hover': {
            cursor: 'pointer'
        }
    },
    extraPositionAdjustment: {
        right: '-10px'
    },
    extraPositionCurrentState: {
        border: 'none',
        right: '-7px'
    },
    hide: {
        display: 'none'
    }
}))