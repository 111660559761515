import {RootState} from './../store'
import {useDispatch, useSelector} from 'react-redux'
import {setFieldValueOfMap} from "../store/features/Map";
import {IDefaultCenter} from '../interface/placesList'

const useDefaultCenter = () => {
    const dispatch = useDispatch()
    const defaultCenter = useSelector((state: RootState) => state.map.defaultCenter)
    const setDefaultCenter = (value: IDefaultCenter) => {
        dispatch(setFieldValueOfMap({
            field: 'defaultCenter',
            value
        }))
    }

    return {
        defaultCenter,
        setDefaultCenter
    }
}

export default useDefaultCenter