import { makeStyles } from '@mui/styles';
import {Theme} from '@mui/material'

export default makeStyles((theme:Theme) => ({
    markerContainer: {
        display: 'flex',
        flexDirection: 'column',
        position: 'absolute',
        transform: 'translate(-50%, -95%)',
        zIndex: 2,
        alignItems: 'center',
        justifyContent: 'center',
        '&:hover': {
            zIndex: 2,
            transform: 'translate(-50%, -95%) scale(1.1)'
        },
        transition: 'opacity 0.5s',
        opacity: 1,
        '& .hide': {
            opacity: 0,
            pointerEvents: 'none',
            transition: 'opacity 0.5s'
        }
    },
    pinImage: {
        width: '40px'
    },
    title: {
        '-webkit-text-stroke-width': '1px',
        '-webkit-text-stroke-color': theme.palette.common.white,
        '&.MuiTypography-root': {
            fontSize: '1.25rem',
            fontWeight: theme.typography.fontWeightBold
        }
    }
}))