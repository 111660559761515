import {createTheme} from '@mui/material/styles'

// @ts-ignore
const theme = createTheme({
    palette: {
        primary: {
            main: '#8DC641'
        },
        secondary: {
            main: '#0099ff'
        },
        text: {
            secondary: 'rgba(153, 153, 153, 0.25)'
        },
        common: {
            black: '#231F20'
        }
    }
})

export {
    theme
}