import React from 'react'
import useStyles from "./styles"
import Scanner from "./components/Scanner";
import {Avatar, IconButton} from "@mui/material";
import SearchLocation from "./components/SearchLocation";
import useToggleScanQR from "../../hooks/useToggleScanQR";
import {useTranslation} from "react-i18next";

const ScanQR = ({IconQr}) => {
    const classes = useStyles()
    const {setToggleScanQR} = useToggleScanQR()
    const { t } = useTranslation()

    return (
        <>
            <IconButton
                edge="start"
                color="inherit"
                onClick={() => {setToggleScanQR(true)}}
                aria-label={t('locationMap.close')}
                className={classes.button}
            >
                <Avatar sx={{ bgcolor: 'rgba(0, 153, 255, 0.1)' }}>
                    <img src={IconQr} className={classes.icon} alt={t('locationMap.scanQR')}/>
                </Avatar>
            </IconButton>
            <Scanner />
            <SearchLocation />
        </>

    )
}

export default ScanQR
