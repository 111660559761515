import React from 'react'
import {useTranslation} from "react-i18next"
import useStyles from './styles'
import currentLocationIcon from "../../../../assets/currentLocation.svg"

function CurrentLocation() {
    const classes = useStyles()
    const { t } = useTranslation()

    return (
        <div className={classes.currentLocationWrapper}>
            <img src={currentLocationIcon}
                 height={50}
                 alt={t('locationMap.currentLocation')}
            />
        </div>
    )
}

export default CurrentLocation